import './App.css';
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

const Login = () => {
  const [token, setToken] = useState("");
  const history = useHistory();

  useEffect(() => {
      sessionStorage.removeItem('reloadCount');
  }, [])

  const submit = () => {
    fetch(`/users/login?t=${token}`)
      .then(() => {history.push('/')})
  }

  return <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{width: "80%", textAlign: 'center'}}>
          <div style={{
              fontSize: 60,
              fontWeight: 'bold',
              backgroundColor: 'black',
              color: 'white',
              paddingBottom: 20,
          }}>JUST<br/>FUCKING<br/>DANCE
          </div>
          <div style={{position: 'relative'}}>
              <input style={{textTransform: 'lowercase'}} type="text" className="inputbox" placeholder="Enter Code Word"
                     value={token}
                     onChange={(event) => setToken(event.target.value)}/>
              <span className="focus-border"><i/></span>
          </div>
          <button
              style={{
                  border: '1px white solid',
                  padding: 10,
                  marginTop: 30,
                  fontWeight: 400,
                  fontSize: 30,
                  color: 'white',
                  backgroundColor: 'black',
              }}
              onClick={submit}>
              Log In
          </button>
      </div>
  </div>
}

export default Login;
