import {useLightAlpha, useLightColor, useMedia, usePreset, useToggle} from "../../common/osc";
import React, {useEffect, useRef, useState} from "react";
import Typed from "typed.js";
import {TextScene} from "../../common/text";

const GetDownTypedText = ({stories}) => {
    const [index, setIndex] = useState(0);
    const [showCharacter, setShowCharacter] = useState(false);

    const el = useRef(null);

    useEffect(() => {
        const fadeOutDelay = 500;
        const backDelay = 3000;

        const typed = new Typed(el.current, {
            strings: stories.map((story) => story.content),
            typeSpeed: 45,
            backSpeed: 10,
            smartBackspace: false,
            cursorChar: ' ',
            backDelay: backDelay,
            fadeOutDelay: fadeOutDelay,
            fadeOut: true,

            preStringTyped: (arrayPos, self) => {
                setIndex(arrayPos);
                setTimeout(() => {
                    if(stories[arrayPos].character) setShowCharacter(true);
                }, 300);
            },
            onStringTyped: (arrayPos, self) => {
                setTimeout(() => {
                    setShowCharacter(false);
                }, backDelay);
            },
        });

        return () => typed.destroy();
    }, [stories]);

    const character = stories[index].character;
    const flip = index % 2 === 0;

    return <span style={{
        width: '1500px',
        height: '900px',
        fontSize: 70
    }}>
        <div style={{height: '330px'}}>
            <span ref={el}/>
        </div>
        <div style={{
            position: 'absolute',
            bottom: 260,
            width: '1500px',
            opacity: showCharacter ? 1 : 0,
            transition: 'all 300ms',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {flip && <img src={character?.image_url} height={350} width={350}/>}
            <div style={{padding: 40, textAlign: flip ? 'left' : 'right'}}>
                <div style={{fontSize: 80}}>{character?.name}</div>
                <div style={{fontSize: 70, fontWeight: 'bold', paddingBottom: 20}}>{character?.nickname}</div>
            </div>
            {!flip && <img src={character?.image_url} height={350} width={350}/>}
        </div>
    </span>;
}
export const GetDownStories = ({front, isControl}) => {
    useMedia('Web');
    useToggle('GetDownText', false);
    useToggle('ColorEffect', false);
    useLightColor(0, 0, 255);
    useLightAlpha(0);

    const [stories, setStories] = useState();

    useEffect(() => {
        if(!isControl) return;
        fetch('/we-get-down/get-stories')
            .then(response => response.json())
            .then(allStories => {
                const stories = front ? allStories.front : allStories.back;
                setStories([...stories, {content: ""}])
            })
    }, []);

    if (!isControl) return <div className={'title-card'} style={{flex: 1, textAlign: 'center', width: '100%'}}>
        <TextScene isControl={isControl} text={'get down'} />
    </div>;

    if (!stories) return null;

    return <div style={{
        flex: 1,
        textAlign: 'center',
        width: '1500px',
        height: '900px',
        alignSelf: 'flex-start',
        display: 'flex',
        justifyContent: 'center'
    }}>
        <GetDownTypedText stories={stories}/>
    </div>;
}

export const WeGetDownChorus = ({isControl, rgb, media, colorEffectEnabled, bgRgb}) => {
    useLightColor(rgb[0], rgb[1], rgb[2]);
    useMedia(media);
    useToggle('GetDownText', true);
    useToggle('ColorEffect', colorEffectEnabled);
    useLightAlpha(1.0);

    const bgColor = bgRgb ? `rgb(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]})` : `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;

    return <div className={'title-card'} style={{
        color: 'black',
        backgroundColor: bgColor,
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }}>
        <TextScene isControl={isControl} text={'get down'} />
    </div>
}

export const WeGetDown = () => {
    usePreset('WeGetDown');
}
