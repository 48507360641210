import React, {useEffect, useState} from "react";
import './GIFEncoder'
import './LZWEncoder'
import './NeuQuant'

function encode64(input) {
    var output = '', i = 0, l = input.length,
        key = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
        chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    while (i < l) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) enc3 = enc4 = 64;
        else if (isNaN(chr3)) enc4 = 64;
        output = output + key.charAt(enc1) + key.charAt(enc2) + key.charAt(enc3) + key.charAt(enc4);
    }
    return output;
}

const loadVideo = (url) => new Promise((resolve, reject) => {
    const vid = document.createElement('video');
    vid.autoplay = true;
    vid.muted = true;
    vid.loop = false;
    vid.playsInline = true;
    vid.src = url;

    vid.addEventListener('canplay', () => resolve(vid));
    vid.addEventListener('error', (err) => reject(err));
});

export const CaptureDancingGif = ({pair, guess, existing, updateTask}) => {
    const defaultStatus = <div>
        <div>Create Animation</div>
        <div style={{fontSize: 20, paddingTop: 40}}>Take turns. In portrait mode, record a 1-5s video of {pair.name} dancing your guess:</div>
        <div style={{fontSize: 30, paddingTop: 20, paddingBottom: 40, fontWeight: 'bold'}}>{guess}</div>
    </div>;

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(defaultStatus);
    const [binaryGif, setBinaryGif] = useState(null);
    const [width, setWidth] = useState(0);
    const [hasCapturedVideo, setHasCapturedVideo] = useState(false);

    useEffect(() => {
        if(hasCapturedVideo) return;

        document.getElementById('video-upload').onchange = async function() {
            let file = this.files[0];
            if(!file) return;

            const videoObj = await loadVideo(URL.createObjectURL(file));

            if (videoObj.duration >= 6) {
                setStatus("Video must be less than 5 seconds!");
                this.value = null;
                return;
            } else if (videoObj.duration < 1) {
                setStatus("Video must be at least 1 second!");
                this.value = null;
                return;
            } else {
                setStatus("Creating Animation 🕐")
            }

            setHasCapturedVideo(true);

            document.getElementById('inputVideoPreview').appendChild(videoObj);
            let _CANVAS = document.createElement('canvas');
            document.getElementById('hiddenCanvas').appendChild(_CANVAS);

            let displayedHeight= 400;
            let displayedWidth= Math.round(displayedHeight * videoObj.videoWidth / videoObj.videoHeight);

            videoObj['style']['height']=`${displayedHeight}px`;
            videoObj['style']['width']=`${displayedWidth}px`;
            _CANVAS['style']['height']=`${displayedHeight}px`;
            _CANVAS['style']['width']=`${displayedWidth}px`;
            _CANVAS.height=displayedHeight;
            _CANVAS.width=displayedWidth;

            // eslint-disable-next-line no-undef
            const encoder = new GIFEncoder();
            encoder.setRepeat(0);
            encoder.setDelay(250);
            encoder.setQuality(4);

            let continueCallback= true;
            let frameIndex= 0;

            const step = async() => {
                if(frameIndex % 6 === 0) {
                    _CANVAS.getContext('2d', { willReadFrequently: true }).drawImage(videoObj, 0, 0, displayedWidth, displayedHeight);
                    encoder.addFrame(_CANVAS.getContext('2d', { willReadFrequently: true }));
                }

                frameIndex++;

                if(continueCallback) {
                    videoObj.requestVideoFrameCallback(step);
                }
            };

            videoObj.addEventListener('play', () => {
                if(continueCallback) videoObj.requestVideoFrameCallback(step);
                encoder.start();
            }, false);

            videoObj.addEventListener('ended', () => {
                continueCallback=false;
                encoder.finish();
                window.URL.revokeObjectURL(videoObj.src);
                document.getElementById('inputVideoPreview').innerHTML = '';
                setStatus('Done!')

                const fileType= 'image/gif';
                const readableStream = encoder.stream();
                const binaryGif = readableStream.getData();
                const b64Str = 'data:'+fileType+';base64,' + encode64(binaryGif);

                setWidth(displayedWidth);
                setBinaryGif(b64Str);
            }, false);

            videoObj.play();
            this.value = null;
        };
    }, [hasCapturedVideo]);

    useEffect(() => {
        if(existing) {
            setStatus('Done!');
            setBinaryGif(existing);
            setWidth(undefined);
            setHasCapturedVideo(true);
        }
    }, [existing]);

    const getControls = () => {
        if(loading) return null;
        if(existing) return null;
        if(!binaryGif) return null;
        return <div>
            <button style={{fontSize: 20, margin: 30}} onClick={() => {
                setStatus(defaultStatus);
                setHasCapturedVideo(false);
                setBinaryGif(null);
            }}>❌ Redo</button>
            <button style={{fontSize: 20, margin: 30}} onClick={() => {
                fetch(binaryGif)
                    .then(result => result.blob())
                    .then((blob) => {
                        setLoading(true);
                        fetch('/dancelestrations/create-dancing-gif', {
                            method: 'post',
                            credentials: 'include',
                            body: blob
                        }).then(() => {
                            updateTask();
                        }).catch(() => {
                            setLoading(false);
                        })
                    })
            }}>✅ Submit</button>
        </div>
    }

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%', height: '100%'}}>
        <div style={{fontSize: 30, color: 'white', margin: '20px'}}>{status}</div>
        {!hasCapturedVideo && <input id="video-upload" className='custom-file-input' type="file" accept="video/*" capture="environment" />}
        <div id='inputVideoPreview' />
        {binaryGif && <img id='outputGif' src={binaryGif} style={{width}} />}
        {getControls()}
        <div id='hiddenCanvas' style={{display: 'none'}}></div>
    </div>;
}