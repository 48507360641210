import QRCode from "react-qr-code";
import {QrReader} from "react-qr-reader";
import React, {useCallback, useEffect, useState} from "react";
import {useLightAlpha, useLightColor} from "../../common/osc";

export const Trilemma = ({isControl, receiveEvents, gameNumber}) => {
    if (isControl) return <TrilemmaControl gameNumber={gameNumber} receiveEvents={receiveEvents}/>
    return <TrilemmaPlayer gameNumber={gameNumber} receiveEvents={receiveEvents}/>;
}

const getEmoji = (moveType) => {
    switch (moveType.toLowerCase()) {
        case 'attack':
            return '⚔️';
        case 'reflect':
            return '🪞';
        case 'heal':
            return '🩹';
        default:
            return '';
    }
};

const getColor = (moveType) => {
    switch (moveType.toLowerCase()) {
        case 'attack':
            return '#ff4136';
        case 'reflect':
            return '#7fdbff';
        case 'heal':
            return '#2ecc40';
        default:
            return '#ffffff';
    }
};

const TrilemmaControl = ({gameNumber, receiveEvents}) => {
    const [gameState, setGameState] = useState();
    const [challenger, setChallenger] = useState();
    const [challengee, setChallengee] = useState();
    const [isShowingLastTurn, setIsShowingLastTurn] = useState(false);
    const [isShowingBattleStart, setIsShowingBattleStart] = useState(false);
    const [lastDisplayedTurn, setLastDisplayedTurn] = useState();

    const lightColor = gameState?.isRedemption ? [255, 0, 0] : [0, 128, 128];
    useLightColor(...lightColor);
    useLightAlpha(1.0);

    const updateGameState = useCallback(() => {
        fetch('/trilemma/update-game-state', {
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({gameNumber})
        }).then(response => response.json())
            .then(setGameState)
    }, []);
    useEffect(() => receiveEvents(updateGameState), []);

    useEffect(() => {
        updateGameState();
    }, [])

    useEffect(() => {
        if (gameState && gameState.finalBattleState && !gameState.finalBattleState.lastTurn && lastDisplayedTurn !== gameState.finalBattleState.turn) {
            setIsShowingBattleStart(true);
            setLastDisplayedTurn(gameState.finalBattleState.turn);
            setTimeout(() => setIsShowingBattleStart(false), 2000);
        } else if (gameState && gameState.finalBattleState && gameState.finalBattleState.lastTurn && lastDisplayedTurn > 0 && lastDisplayedTurn !== gameState.finalBattleState.turn) {
            setIsShowingLastTurn(true);
            setLastDisplayedTurn(gameState.finalBattleState.turn);
            setTimeout(() => setIsShowingLastTurn(false), gameState.finalBattleState.winner ? 5000 : 3000);
        }
    }, [gameState])

    const fakeBattle = () => {
        if(!challenger || !challengee) return;
        fetch(`/trilemma/fake-battle?gameNumber=${gameNumber}`, {
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({gameNumber, challenger, challengee})
        }).then(() => {
                updateGameState();
                setChallenger('');
                setChallengee('');
            })
    }

    if(!gameState) return;

    let battleView = null;
    if(gameState.remainingPlayers.length === 1 && gameState.winningPlayers.length === 0 && gameState.eliminatedPlayers.length === 0 && gameState.inBattlePlayers.length === 0) {
        battleView = <>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{fontSize: 90, textShadow: '0 0 5px #ffffff, 0 0 5px #ffffff'}}>🎊🎊🎊</div>
                <div style={{fontSize: 80, textShadow: '0 0 5px #ffffff, 0 0 5px #ffffff'}}>🏆 CHAMPION 🏆</div>
                <div style={{
                    width: '100%',
                    paddingTop: 6,
                    paddingBottom: 6,
                    fontSize: 100,
                    display: 'flex',
                    textAlign: 'left',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img style={{paddingRight: 40}} src={gameState.remainingPlayers[0].image_url}
                         height={350}
                         width={350}/>
                    <div>
                        <div style={{fontWeight: 'bold'}}>{gameState.remainingPlayers[0].name}</div>
                        <div style={{fontSize: 90}}>{gameState.remainingPlayers[0].nickname}</div>
                    </div>
                </div>
                <div style={{fontSize: 90, marginTop: 20, textShadow: '0 0 5px #ffffff, 0 0 5px #ffffff'}}>🎊🎊🎊</div>
            </div>
        </>
    } else if (gameState.finalBattleState && !gameState.finalBattleState.winner) {
        const battle = gameState.finalBattleState;
        battleView = <div style={{width: '100%', height: '100%'}}>
            {gameState.isRedemption && <div className={'digital'} style={{
                fontSize: 30,
                marginTop: 20,
                marginBottom: 20,
                color: '#AA0000',
                textAlign: 'center',
                width: '100%'
            }}>REDEMPTION BATTLE
            </div>}
            <div style={{fontSize: 80, marginTop: 20}}>Turn {battle.turn}</div>
            <div style={{fontSize: 40, textAlign: 'center', fontWeight: 'bold', marginBottom: 30}}>
                <div style={{color: '#ffffff'}}>
                    {battle.lastTurn &&
                        !battle.lastTurn.challengerHealed &&
                        !battle.lastTurn.challengerDamaged &&
                        !battle.lastTurn.challengeeHealed &&
                        !battle.lastTurn.challengeeDamaged
                        && '• Nothing Happened!'
                    }</div>
                <div style={{color: '#2ecc40'}}>{battle.lastTurn && battle.lastTurn.challengerHealed && `• ${battle.challenger.name} Healed!`}</div>
                <div style={{color: '#f30a00'}}>{battle.lastTurn && battle.lastTurn.challengerDamaged && `• ${battle.challenger.name} Took Damage!`}</div>
                <div style={{color: '#2ecc40'}}>{battle.lastTurn && battle.lastTurn.challengeeHealed && `• ${battle.challengee.name} Healed!`}</div>
                <div style={{color: '#f30a00'}}>{battle.lastTurn && battle.lastTurn.challengeeDamaged && `• ${battle.challengee.name} Took Damage!`}</div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                width: '100%',
            }}>
                <div>
                    <div style={{fontSize: 80, marginBottom: 8}}>{battle.challenger.name}</div>
                    <img style={{width: '350px', border: battle.challengerCritical ? '20px #AA1111 solid' : ''}}
                         src={battle.challenger.image_url}/>
                    <div style={{fontSize: 50}}>{battle.challenger.nickname}</div>
                    <div className={'digital'} style={{
                        paddingTop: 20,
                        fontSize: 30,
                        color: battle.challengerCritical ? '#FF1111' : 'black'
                    }}>CRITICAL</div>
                </div>
                <div style={{fontSize: 90}}>vs.</div>
                <div>
                    <div style={{fontSize: 80, marginBottom: 8}}>{battle.challengee.name}</div>
                    <img style={{width: '350px', border: battle.challengeeCritical ? '20px #AA1111 solid' : ''}}
                         src={battle.challengee.image_url}/>
                    <div style={{fontSize: 50}}>{battle.challengee.nickname}</div>
                    <div className={'digital'} style={{
                        paddingTop: 20,
                        fontSize: 30,
                        color: battle.challengeeCritical ? '#FF1111' : 'black'
                    }}>CRITICAL</div>
                </div>
            </div>
        </div>

    } else if (gameState.isRedemption) {
        battleView = <>
            <div className={'digital'} style={{
                fontSize: 50,
                marginTop: 20,
                marginBottom: 20,
                color: '#AA0000',
                textAlign: 'center',
                width: '100%'
            }}>REDEMPTION BATTLE
            </div>
            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%'}}>
                {gameState.remainingPlayers.map((player, index) =>
                    <div key={index} style={{
                        width: '100%',
                        margin: '8px',
                        padding: '4px',
                        textAlign: 'center'
                    }}>
                        <div style={{fontSize: 38, marginBottom: 8}}>{player.name}</div>
                        <img style={{width: '150px'}} src={player.image_url}/>
                    </div>)
                }
            </div>
            <div style={{alignSelf: 'center', marginTop: 40, fontSize: 30}}>Choose</div>
            <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                {gameState.eliminatedPlayers.map((player, index) =>
                    <div key={index} style={{
                        width: '110px',
                        height: '120px',
                        margin: '8px',
                        padding: '4px',
                    }}>
                        <img style={{width: '100px', display: 'block', margin: 'auto'}} src={player.image_url}/>
                        <div style={{fontSize: 28}}>{player.name}</div>
                    </div>)
                }
            </div>
        </>
    } else {
        battleView = <>
            <div style={{width: '100%', borderBottom: '1px solid white', marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                <div className='digital' style={{fontSize: 44}}>TRILEMMA</div>
                <div className='digital' style={{fontSize: 44}}>Round {gameState.round}</div>
            </div>
            {gameState.winningPlayers.length > 0 &&
                <div style={{marginTop: 10, paddingLeft: 12, fontWeight: 'bold', marginBottom: '-10px', fontSize: 30}}>WINNERS</div>}
            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                {gameState.winningPlayers.map((player, index) =>
                    <div key={index} style={{
                        width: '110px',
                        height: '120px',
                        margin: '8px',
                        padding: '4px',
                    }}>
                        <img style={{width: '100px', display: 'block', margin: 'auto'}} src={player.image_url}/>
                        <div style={{fontSize: 28}}>{player.name}</div>
                    </div>)
                }
            </div>
            {gameState.inBattlePlayers.length > 0 &&
                <div style={{marginTop: 12, fontWeight: 'bold', marginBottom: '-10px', paddingLeft: 16, fontSize: 30}}>IN BATTLE</div>}
            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                {gameState.inBattlePlayers.map((player, index) =>
                    <div key={index} style={{
                        width: '110px',
                        height: '120px',
                        margin: '8px',
                        padding: '4px',
                    }}>
                        <img style={{width: '100px', display: 'block', margin: 'auto'}} src={player.image_url}/>
                        <div style={{fontSize: 28}}>{player.name}</div>
                    </div>)
                }
            </div>
            {gameState.remainingPlayers.length > 0 &&
                <div style={{marginTop: 12, fontWeight: 'bold', marginBottom: '-10px', paddingLeft: 16, fontSize: 30}}>REMAINING</div>}
            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                {gameState.remainingPlayers.map((player, index) =>
                    <div key={index} style={{
                        width: '110px',
                        height: '120px',
                        margin: '8px',
                        padding: '4px',
                    }}>
                        <img style={{width: '100px', display: 'block', margin: 'auto'}} src={player.image_url}/>
                        <div style={{fontSize: 28}}>{player.name}</div>
                    </div>)
                }
            </div>
        </>

    }

    if(gameNumber === 0) {
        return <div className='digital' style={{
            height: '900px',
            width: '1500px',
            overflow: 'hidden',
            textAlign: 'center',
            alignSelf: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 100,
        }}>
            TRILEMMA
        </div>
    }

    return <div style={{
        height: '900px',
        width: '1500px',
        overflow: 'hidden',
        textAlign: 'center',
        alignSelf: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }}>
        <style>
            {`
          @keyframes enlarge {
            0% { transform: scale(100%); }
            100% { transform: scale(150%); }
          }
          @keyframes enlarge-delay {
            0% { transform: scale(100%); opacity: 0; }
            25% { opacity: 0; }
            35% { opacity: 1; }
            100% { transform: scale(150%); }
          }
          @keyframes slideLeft {
            0% { transform: translateX(5%); opacity: 0; }
            10% { opacity: 1; }
            100% { transform: translateX(-10%); }
          }
          @keyframes slideRight {
            0% { transform: translateX(-5%); opacity: 0; }
            10% { opacity: 0; }
            20% { opacity: 1; }
            100% { transform: translateX(10%); }
          }
        `}
        </style>
        <div style={{
            position: 'absolute',
            pointerEvents: isShowingBattleStart ? 'auto' : 'none',
            backgroundColor: isShowingBattleStart ? 'black' : 'white',
            height: '900px',
            width: '1500px',
            opacity: isShowingBattleStart ? 1 : 0,
            transition: isShowingBattleStart ? 'none' : 'all 0.4s ease'
        }}>
            {isShowingBattleStart && <div style={{
                width: '100%',
                animation: 'enlarge 4s linear infinite',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{fontSize: 80}}>Battle Start!</div>
            </div>}
        </div>
        <div style={{
            position: 'absolute',
            height: '900px',
            width: '1500px',
            pointerEvents: isShowingLastTurn ? 'auto' : 'none',
            backgroundColor: isShowingLastTurn ? 'black' : 'white',
            opacity: isShowingLastTurn ? 1 : 0,
            transition: isShowingLastTurn ? 'none' : 'all 0.4s ease'
        }}>
            {isShowingLastTurn && <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textShadow: `2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black`,
            }}>
                <div style={{
                    animation: 'slideLeft 8s linear infinite',
                    color: 'white',
                    display: 'flex',
                    marginBottom: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('brush-2.png')`,
                    backgroundPosition: 'left',
                    backgroundSize: 'stretch',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{marginRight: 20, textAlign: 'center'}}>
                        <div style={{fontSize: 70}}>{gameState.finalBattleState.challenger.name} used</div>
                        <div style={{
                            fontSize: 80,
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: getColor(gameState.finalBattleState.lastTurn.challengerMove),
                        }}>{getEmoji(gameState.finalBattleState.lastTurn.challengerMove)} {gameState.finalBattleState.lastTurn.challengerMove}</div>
                    </div>
                    <div style={{textAlign: 'center', backgroundColor: 'black', padding: 5}}>
                        <img src={gameState.finalBattleState.challenger.image_url} height={200} width={200}/>
                        <div style={{
                            fontSize: 40,
                        }}>{gameState.finalBattleState.challenger.nickname}</div>
                    </div>
                </div>
                <div style={{
                    animation: 'slideRight 8s linear infinite',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('brush-1.png')`,
                    backgroundPosition: 'right',
                    backgroundSize: 'stretch',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div style={{textAlign: 'center', padding: 5}}>
                        <img src={gameState.finalBattleState.challengee.image_url} height={200} width={200}/>
                        <div style={{
                            fontSize: 40,
                        }}>{gameState.finalBattleState.challengee.nickname}</div>
                    </div>
                    <div style={{marginLeft: 12, textAlign: 'center'}}>
                        <div style={{fontSize: 70}}>{gameState.finalBattleState.challengee.name} used</div>
                        <div style={{
                            fontSize: 80,
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: getColor(gameState.finalBattleState.lastTurn.challengeeMove),
                        }}>{getEmoji(gameState.finalBattleState.lastTurn.challengeeMove)} {gameState.finalBattleState.lastTurn.challengeeMove}</div>
                    </div>
                </div>
                {gameState.finalBattleState.winner && <div style={{
                    position: 'absolute',
                    animation: 'enlarge-delay 8s linear infinite',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '140px',
                    width: '100%',
                    backgroundColor: 'rgba(3,87,0,0.9)',
                }}>
                    <div style={{textAlign: 'center', padding: 5}}>
                        {gameState.finalBattleState.winner.name} wins!
                    </div>
                </div>}
            </div>
            }
        </div>
        {battleView}
        <div style={{
            position: 'absolute',
            width: '500px',
            bottom: 34,
            left: 800,
            right: 0,
            margin: 'auto'
        }}>
            <div style={{display: 'flex', }}>
                <input type="text" className="inputbox" placeholder={'Challenger'} value={challenger}
                       onChange={(event) => setChallenger(event.target.value)}/>
                <input type="text" className="inputbox" placeholder={'Challengee'} value={challengee}
                       onChange={(event) => setChallengee(event.target.value)}/>
                <button
                    style={{
                        border: '1px white solid',
                        padding: 10,
                        fontWeight: 400,
                        fontSize: 30,
                        color: 'white',
                        backgroundColor: 'black',
                    }}
                    onClick={fakeBattle}>Submit
                </button>
            </div>

        </div>
    </div>
}

const TrilemmaPlayer = ({gameNumber, receiveEvents}) => {
    const [isScanning, setIsScanning] = useState(false);
    const [gameState, setGameState] = useState();
    const [isShowingBattleStart, setIsShowingBattleStart] = useState(false);
    const [isShowingLastTurn, setIsShowingLastTurn] = useState(false);
    const [lastDisplayedTurn, setLastDisplayedTurn] = useState();
    const [opponentToken, setOpponentToken] = useState();

    useEffect(() => receiveEvents(fetchGameState), []);

    const fetchGameState = () => {
        fetch(`/trilemma/get-game-state-for-player?gameNumber=${gameNumber}`, {
            credentials: 'include'
        }).then(response => response.json())
            .then(setGameState)
    }

    useEffect(() => {
        fetchGameState();
    }, [])

    useEffect(() => {
        if (gameState && gameState['state'] === 'in-battle' && !gameState['lastTurn'] && !gameState['selectedMove'] && lastDisplayedTurn !== gameState['turn']) {
            setIsShowingBattleStart(true);
            setLastDisplayedTurn(gameState['turn']);
            setTimeout(() => setIsShowingBattleStart(false), 3000);
        } else if (gameState && gameState['lastTurn'] && !gameState['selectedMove'] && lastDisplayedTurn !== gameState['turn']) {
            setIsShowingLastTurn(true);
            setLastDisplayedTurn(gameState['turn']);
            setTimeout(() => setIsShowingLastTurn(false), 3000);
        }
    }, [gameState])

    const playAgain = () => {
        fetch('/trilemma/play-again', {
            method: 'post',
            credentials: 'include',
        }).then(() => fetchGameState())
    }

    const startBattle = ({opponentToken}) => {
        fetch('/trilemma/start-battle', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({gameNumber, opponent_token: opponentToken})
        }).then(() => fetchGameState())
    }

    const performMove = ({move}) => {
        setGameState(prevState => ({...prevState, selectedMove: move}));
        fetch('/trilemma/perform-move', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({gameNumber, move})
        }).then(() => fetchGameState())
    }

    if (!gameState) return;
    let battleView;

    if (gameState['state'] === "winner") battleView = <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <div style={{fontSize: 40}}>🎊🎊🎊</div>
        <div style={{fontSize: 40}}>You Defeated</div>
        <div style={{
            backgroundColor: '#1e1e1e',
            width: '100%',
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 26,
            display: 'flex',
            textAlign: 'left',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img style={{paddingLeft: 10, paddingRight: 10}} src={gameState.opponent.image_url} height={100}
                 width={100}/>
            <div>
                <div style={{fontWeight: 'bold'}}>{gameState.opponent.name}</div>
                <div style={{fontSize: 20}}>{gameState.opponent.nickname}</div>
            </div>
        </div>
        <div style={{fontSize: 40, marginTop: 10}}>🎊🎊🎊</div>
        {gameNumber === 0 && <button
            onClick={playAgain}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                marginTop: '20px',
                border: '2px solid darkgreen',
                borderRadius: '8px',
                backgroundColor: 'green',
                color: 'white',
                fontSize: '32px',
                fontWeight: 'bold',
            }}
        >
            PLAY AGAIN
        </button>}
    </div>

    if (gameState['state'] === "eliminated") battleView = <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    }}>
        <div style={{fontSize: 40}}>☠️☠️☠️</div>
        <div style={{fontSize: 40}}>Eliminated By</div>
        <div style={{
            backgroundColor: '#1e1e1e',
            width: '100%',
            marginTop: 10,
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 26,
            display: 'flex',
            textAlign: 'left',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img style={{paddingLeft: 10, paddingRight: 10}} src={gameState.opponent.image_url} height={100}
                 width={100}/>
            <div>
                <div style={{fontWeight: 'bold'}}>{gameState.opponent.name}</div>
                <div style={{fontSize: 20}}>{gameState.opponent.nickname}</div>
            </div>
        </div>
        <div style={{fontSize: 40, marginTop: 10}}>☠️☠️☠️</div>

        {gameNumber === 0 && <button
            onClick={playAgain}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                marginTop: '20px',
                border: '2px solid darkgreen',
                borderRadius: '8px',
                backgroundColor: 'green',
                color: 'white',
                fontSize: '32px',
                fontWeight: 'bold',
            }}
        >
            PLAY AGAIN
        </button>}
    </div>

    if (gameState['state'] === "in-battle") battleView = <>
        <div style={{fontSize: 50}}>Turn {gameState['turn']}</div>
        <div style={{
            backgroundColor: '#1e1e1e',
            width: '100%',
            marginTop: 10,
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 26,
            display: 'flex',
            textAlign: 'left',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{fontSize: 30}}>vs.</div>
            <img
                style={{marginLeft: 10, marginRight: 10, border: gameState.opponentCritical ? '5px #AA1111 solid' : ''}}
                src={gameState.opponent.image_url} height={100} width={100}/>
            <div>
                {gameState.opponentCritical &&
                    <div className={'digital'} style={{paddingTop: 10, fontSize: 10, color: '#FF1111'}}>CRITICAL</div>}
                <div style={{fontWeight: 'bold'}}>{gameState.opponent.name}</div>
                <div style={{fontSize: 20}}>{gameState.opponent.nickname}</div>
            </div>
        </div>
        <div style={{fontSize: 20, height: 66, marginTop: 14, textAlign: 'center', fontWeight: 'bold'}}>
            <div style={{color: '#ffffff'}}>
                {gameState['lastTurn'] &&
                    !gameState['lastTurn']['playerHealed'] &&
                    !gameState['lastTurn']['playerDamaged'] &&
                    !gameState['lastTurn']['opponentHealed'] &&
                    !gameState['lastTurn']['opponentDamaged']
                    && '• Nothing Happened!'
                }</div>
            <div
                style={{color: '#2ecc40'}}>{gameState['lastTurn'] && gameState['opponentCritical'] && '• Opponent Critical!'}</div>
            <div
                style={{color: '#2ecc40'}}>{gameState['lastTurn'] && gameState['lastTurn']['playerHealed'] && '• You Healed!'}</div>
            <div
                style={{color: '#f30a00'}}>{gameState['lastTurn'] && gameState['lastTurn']['playerDamaged'] && '• You Took Damage!'}</div>
            <div
                style={{color: '#f30c00'}}>{gameState['lastTurn'] && gameState['lastTurn']['opponentHealed'] && '• Opponent Healed!'}</div>
        </div>
        {!gameState['selectedMove'] && <div style={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}>
            <div style={{fontWeight: 'bold'}}>CHOOSE MOVE</div>
            <MoveButton move={'Attack'} performMove={performMove}/>
            <MoveButton move={'Reflect'} performMove={performMove}/>
            <MoveButton move={'Heal'} performMove={performMove}/>
        </div>}
        {gameState['selectedMove'] && <div style={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}>
            <div style={{fontWeight: 'bold'}}>YOU CHOSE</div>
            <MoveButton move={'Attack'} hidden={gameState['selectedMove'] !== 'ATTACK'}
                        selected={gameState['selectedMove'] === 'ATTACK'} performMove={() => {
            }}/>
            <MoveButton move={'Reflect'} hidden={gameState['selectedMove'] !== 'REFLECT'}
                        selected={gameState['selectedMove'] === 'REFLECT'} performMove={() => {
            }}/>
            <MoveButton move={'Heal'} hidden={gameState['selectedMove'] !== 'HEAL'}
                        selected={gameState['selectedMove'] === 'HEAL'} performMove={() => {
            }}/>
        </div>}
    </>

    if (gameState['state'] === "start-battle") battleView = <div style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {gameNumber !== 0 && <div style={{fontSize: 60, marginBottom: 20}}>Round {gameState['round']}</div>}
        {isScanning && <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '256px',
            height: '256px',
            border: '12px solid white',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        }}>
            <QrReader
                onResult={(result, error) => {
                    if (!!result && result.text) {
                        setIsScanning(false);
                        setGameState({});
                        startBattle({opponentToken: result.text});
                    }

                    if (!!error) {
                        console.info(error);
                    }
                }}
                containerStyle={{width: '100%'}}
                constraints={{facingMode: 'environment'}}
            />
        </div>}
        {!isScanning && <div>
            <div style={{background: 'white', border: '12px solid white'}}>
                <QRCode
                    style={{height: "auto", width: "100%"}}
                    value={gameState.token}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </div>}
        <button
            onClick={() => setIsScanning(!isScanning)}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '300px',
                height: '100px',
                marginTop: '20px',
                border: '2px solid',
                borderColor: 'white',
                borderRadius: '8px',
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
            }}
        >
            {!isScanning && <span style={{textTransform: 'uppercase', fontSize: 30}}><span style={{fontSize: 40}}>📷</span> Scan</span>}
            {isScanning && <span style={{textTransform: 'uppercase', fontSize: 30}}>Cancel</span>}
        </button>
    </div>

    if (gameState['state'] === "redeemer") battleView = <div style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div style={{fontSize: 30, marginBottom: 10, color: '#AA0000'}}>REDEEMER</div>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '256px',
            height: '256px',
            border: '12px solid #AA0000',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        }}>
            <QrReader
                onResult={(result, error) => {
                    if (!!result && result.text) {
                        setIsScanning(false);
                        setGameState({});
                        startBattle({opponentToken: result.text});
                    }

                    if (!!error) {
                        console.info(error);
                    }
                }}
                containerStyle={{width: '100%'}}
                constraints={{facingMode: 'environment'}}
            />
        </div>
        <div style={{fontSize: 30, marginTop: 10, color: '#AA0000'}}>Scan to Redeem</div>
    </div>

    if (gameState['state'] === "redeemable") battleView = <div style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <div>
            <div style={{fontSize: 30, marginBottom: 10, textAlign: 'center'}}>REDEMPTION</div>
            <div style={{background: 'white', border: '12px solid white'}}>
                <QRCode
                    style={{height: "auto", width: "100%"}}
                    value={gameState.token}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </div>
    </div>


    // if (gameState['state'] === "start-battle") battleView = <>
    //     <div style={{height: "200", width: '200'}}>
    //         <div style={{background: 'white', padding: '16px'}}>
    //             <QRCode
    //                 style={{height: "auto", maxWidth: "100%", width: "100%"}}
    //                 value={gameState.token}
    //                 viewBox={`0 0 256 256`}
    //             />
    //         </div>
    //     </div>
    //     <div style={{position: 'relative'}}>
    //         <input type="text" className="inputbox" placeholder={'Token to Battle'} value={opponentToken}
    //                onChange={(event) => setOpponentToken(event.target.value)}/>
    //         <span className="focus-border"><i/></span>
    //     </div>
    //     <button
    //         style={{
    //             border: '1px white solid',
    //             padding: 10,
    //             margin: 30,
    //             fontWeight: 400,
    //             fontSize: 30,
    //             color: 'white',
    //             backgroundColor: 'black'
    //         }} onClick={() => startBattle({opponentToken})}>Battle
    //     </button>
    // </>

    const isCritical = gameState && gameState['critical'];
    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }}>
        <style>
            {`
          @keyframes enlarge {
            0% { transform: scale(100%); }
            100% { transform: scale(150%); }
          }
          @keyframes slideLeft {
            0% { transform: translateX(5%); opacity: 0; }
            10% { opacity: 1; }
            100% { transform: translateX(-10%); }
          }
          @keyframes slideRight {
            0% { transform: translateX(-5%); opacity: 0; }
            10% { opacity: 0; }
            20% { opacity: 1; }
            100% { transform: translateX(10%); }
          }
        `}
        </style>
        <div style={{
            position: 'absolute',
            pointerEvents: isShowingBattleStart ? 'auto' : 'none',
            backgroundColor: isShowingBattleStart ? 'black' : 'white',
            width: '100%',
            height: '100%',
            opacity: isShowingBattleStart ? 1 : 0,
            transition: isShowingBattleStart ? 'none' : 'all 0.4s ease'
        }}>
            {isShowingBattleStart && <div style={{
                width: '100%',
                animation: 'enlarge 8s linear infinite',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{fontSize: 30}}>Battle Start!</div>
                <div style={{fontSize: 30, marginTop: 20}}>vs.</div>
                <div style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{textAlign: 'center', padding: 5}}>
                        <img src={gameState.opponent.image_url} height={100} width={100}/>
                        <div style={{fontSize: 30, fontWeight: 'bold'}}>{gameState.opponent.name}</div>
                        <div style={{fontSize: 20}}>{gameState.opponent.nickname}</div>
                    </div>
                </div>
            </div>}
        </div>
        <div style={{
            position: 'absolute',
            pointerEvents: isShowingLastTurn ? 'auto' : 'none',
            backgroundColor: isShowingLastTurn ? 'black' : 'white',
            width: '100%',
            height: '100%',
            opacity: isShowingLastTurn ? 1 : 0,
            transition: isShowingLastTurn ? 'none' : 'all 0.4s ease'
        }}>
            {isShowingLastTurn && <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textShadow: `2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black`,
            }}>
                <div style={{
                    animation: 'slideLeft 8s linear infinite',
                    color: 'white',
                    display: 'flex',
                    marginBottom: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('brush-2.png')`,
                    backgroundPosition: 'left',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{marginRight: 20, textAlign: 'right'}}>
                        <div style={{fontSize: 30}}>You used</div>
                        <div style={{
                            fontSize: 40,
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: getColor(gameState.lastTurn.playerMove),
                        }}>{getEmoji(gameState.lastTurn.playerMove)} {gameState.lastTurn.playerMove}</div>
                    </div>
                    <div style={{textAlign: 'center', backgroundColor: 'black', padding: 5}}>
                        <img src={gameState.player.image_url} height={100} width={100}/>
                        <div style={{fontSize: 20, width: '100px'}}>{gameState.player.nickname}</div>
                    </div>
                </div>
                <div style={{
                    animation: 'slideRight 8s linear infinite',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('brush-1.png')`,
                    backgroundPosition: 'right',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div style={{textAlign: 'center', padding: 5}}>
                        <img src={gameState.opponent.image_url} height={100} width={100}/>
                        <div style={{fontSize: 20, width: '100px'}}>{gameState.opponent.nickname}</div>
                    </div>
                    <div style={{marginLeft: 12, textAlign: 'left'}}>
                        <div style={{fontSize: 30}}>{gameState.opponent.name} used</div>
                        <div style={{
                            fontSize: 40,
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: getColor(gameState.lastTurn.opponentMove),
                        }}>{getEmoji(gameState.lastTurn.opponentMove)} {gameState.lastTurn.opponentMove}</div>
                    </div>
                </div>
            </div>}
        </div>

        {!isShowingBattleStart && !isShowingLastTurn && <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            border: isCritical ? '10px #800000 solid' : 'none',
            boxSizing: 'border-box',
        }}>
            {isCritical && <PulsingCriticalComponent isCritical={isCritical}/>}
            {battleView}
        </div>}
    </div>;
}

const PulsingCriticalComponent = () => {
    return (
        <div
            className="digital pulsing-critical"
            style={{
                marginTop: 12,
                fontSize: 16,
                padding: 6,
                color: '#FF0000',
                fontWeight: 'bold',
                fontStyle: 'italic',
                borderRadius: '8px',
                border: '1px solid black',
                display: 'inline-block',
                transition: 'all 500ms',
                animation: 'pulse 2s infinite',
            }}
        >
            CRITICAL
            <style>
                {`
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
              color: #FF1111;
            }
            70% {
              box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
              color: #950000;
            }
            100% {
              box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
              color: #FF1111;
            }
          }
        `}
            </style>
        </div>
    );
};

const MoveButton = ({move, performMove, selected, hidden}) => {
    const getGradient = (moveType) => {
        switch (moveType.toLowerCase()) {
            case 'attack':
                return 'linear-gradient(335deg, #ff5a5a, #e60000)';
            case 'reflect':
                return 'linear-gradient(335deg, #99e5ff, #00a3cc)';
            case 'heal':
                return 'linear-gradient(335deg, #4ddb60, #00b300)';
            default:
                return 'linear-gradient(335deg, #f5f5f5, #d9d9d9)';
        }
    };


    const getDescription = (moveType) => {
        switch (moveType.toLowerCase()) {
            case 'attack':
                return 'Deals damage to Heal';
            case 'reflect':
                return <span>Deals damage to Attack,<br/>takes damage from Heal</span>;
            case 'heal':
                return 'Recovers from Critical State';
            default:
                return '';
        }
    };

    return (
        <button
            onClick={() => performMove({move: move.toUpperCase()})}
            style={{
                opacity: hidden ? 0 : 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '80%',
                padding: '10px 15px',
                margin: '5px 0',
                border: '2px solid',
                borderColor: getColor(move),
                background: selected ? getGradient(move) : 'black',
                borderRadius: '8px',
                color: selected ? 'white' : getColor(move),
                fontSize: '18px',
                fontWeight: 'bold',
                transition: 'all 0.3s ease',
                flex: 1
            }}
        >
      <span style={{fontSize: '44px', marginRight: '10px'}}>
        {getEmoji(move)}
      </span>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1}}>
                <span style={{textTransform: 'uppercase'}}>{move}</span>
                <span
                    style={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                    }}
                >
          {getDescription(move)}
        </span>
            </div>
        </button>
    );
};