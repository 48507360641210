import React, {useCallback, useEffect, useState} from "react";
import {usePolling} from "../../common/effects";
import {usePreset} from "../../common/osc";

export const CharacterCreator = ({isControl, name, receiveEvents}) => {
    if (isControl) return <CharactersReady />
    else return <CreateCharacter name={name} receiveEvents={receiveEvents} />;
}

const CharactersReady = () => {
    const [ready, setReady] = useState();
    const isReady = ready && ready.ready === ready.total;

    const pollingFunction = useCallback(() => {
        fetch('/character-creator/get-ready-status')
            .then(response => response.json())
            .then(setReady)
    }, []);
    usePolling(pollingFunction, 1000);

    usePreset(isReady ? 'Ready' : 'Intro');

    if (!ready) return null;

    const getWaitingText = () => {
        if (ready.not_ready.length) {
            return <>Waiting on {ready.not_ready.join(', ')}</>;
        }
    }

    const countColor = isReady ? '#0db704' : '#ffffff';

    return <div style={{alignSelf: 'flex-start', height: '900px', width: '1500px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div className={'digital'} style={{fontSize: 120, paddingBottom: 20, color: countColor}}>{ready.ready}/{ready.total}</div>
        <div style={{fontSize: 50}}>{getWaitingText()}</div>
    </div>;
}

export const CreateCharacter = ({name, receiveEvents}) => {
    const [emblem, setEmblem] = useState("");
    const [fact, setFact] = useState("");
    const [savedCharacter, setSavedCharacter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        return receiveEvents((event) => {
            if(event.type === 'character-creator-progress') {
                setProgress((progress) => {
                    if(progress + 1 === 6) getSavedCharacter();
                    return progress + 1
                });
            }
            else if(event.type === 'character-creator-error') {
                setProgress(0);
                getSavedCharacter();
            }
        });
    }, []);

    const getSavedCharacter = () => {
        fetch('/character-creator/get-character', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then((json) => {
                setSavedCharacter(json);
                setLoading(false);
            })
    }

    useEffect(() => {
        getSavedCharacter()
    }, [])

    const submitAnswer = () => {
        if(!emblem || !fact) return;
        setLoading(true);
        fetch('/character-creator/create-character', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({emblem, fact})
        }).then(() => {})
        .catch(() => getSavedCharacter())
    }

    const getInputArea = () => {
        if (savedCharacter) {
            return <>
                <div style={{fontSize: 40, padding: 20}}>Ready ✅</div>
                <div style={{
                    border: '2px solid white',
                    backgroundColor: '#151515',
                    padding: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 330,
                    marginBottom: 10
                }}>
                    <img src={savedCharacter.image_url} height={315} width={315}/>
                    <div style={{fontSize: 40, paddingTop: 10}}>{name}</div>
                    <div style={{fontSize: 28, fontWeight: 'bold'}}>{savedCharacter.nickname}</div>
                    <div style={{fontSize: 20, textAlign: 'center', paddingTop: 10}}>{savedCharacter.fact}</div>
                </div>
            </>
        } else {
            return <>
                <p style={{fontSize: 40, textAlign: 'center', marginTop: 20, marginBottom: 60}}>{name}</p>
                <div>
                    <div style={{marginBottom: 60}}>
                        <input type="text" className="inputbox" placeholder="Your Emblem" value={emblem}
                               onChange={(event) => setEmblem(event.target.value)}/>
                        <p style={{textAlign: 'right', marginTop: 2}}><i>e.g.</i>  "a drum stick"</p>
                    </div>
                    <div style={{marginBottom: 60}}>
                        <input type="text" className="inputbox" placeholder="Fact About You" value={fact}
                               onChange={(event) => setFact(event.target.value)}
                        />
                        <p style={{textAlign: 'right', marginTop: 2}}><i>e.g.</i> "I can snowboard a double black diamond."<br/></p>
                    </div>
                </div>
                <button
                    style={{
                        marginBottom: 20,
                        border: '1px white solid',
                        padding: 10,
                        fontWeight: 400,
                        fontSize: 30,
                        color: 'white',
                        backgroundColor: 'black'
                    }}
                    onClick={submitAnswer}>Create Character
                </button>
            </>
        }
    }

    if (loading) return <div className="evenly-distribute-vertical" style={{textAlign: 'center'}}>
        <span>
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div style={{fontSize: 30, marginTop: 20}}>Building<br />Character</div>
            <div style={{marginTop: 30}}>{'💰'.repeat(progress) + '🟡'.repeat(6 - progress)}</div>
            <div style={{marginTop: 10}}>This is gonna take a while - don't refresh.</div>
        </span>
    </div>;;

    return <div style={{position: 'absolute', top: 0, margin: '0 auto 0 auto', textAlign: 'center'}}>
        {getInputArea()}
    </div>;
}