import './App.css';
import React, {useState} from "react";

export const Notify = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isConsenting, setIsConsenting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const submitAnswer = () => {
    setIsDisabled(true)

    fetch('/users/register', {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({name})
    }).then(response => response.json())
      .then((registration) => {
        setName("")
        setIsRegistered(true)
      }).finally(() => {
        setIsDisabled(false)
      })
  }

  return <div style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
      <div className={'evenly-distribute-vertical'} style={{textAlign: 'center', fontSize: 20, maxWidth: '600px'}}>
      <img alt='JUST FUCKING DANCE' src='title.jpg' style={{width: '100%', borderBottom: '1px solid white'}} />
      <input style={{width: '80%'}} type="text" className="inputbox" placeholder="Name" value={name}
             onChange={(event) => setName(event.target.value)}/>
      <input style={{width: '80%'}} type="text" className="inputbox" placeholder="Email" value={email}
             onChange={(event) => setEmail(event.target.value)}/>
      <label style={{width: '80%'}}>
          <input
              type="checkbox"
              checked={isConsenting}
              onChange={(e) => setIsConsenting(e.target.checked)}
          />
          Notify me when I am able to play Just Fucking Dance
      </label>
      <button
          style={{
            border: '1px white solid',
            padding: 10,
            marginTop: 30,
            fontWeight: 400,
            fontSize: 30,
            color: 'white',
            backgroundColor: 'black'
          }}
          disabled={isDisabled}
          onClick={submitAnswer}>
        Sign Up
      </button>
  </div>
  </div>
}
