import {useState} from "react";

export const Init = () => {
    const [chatReady, setChatReady] = useState('❌');
    const [trilemmaReady, setTrilemmaReady] = useState('❌');
    const [danceStopReady, setDanceStopReady] = useState('❌');
    const [chaosQuestReady, setChaosQuestReady] = useState('❌');

    const initAll = () => {
        // fetch('/button-pusher/init').then(() => {});
        // fetch('/drawing-story/init', {cache: "no-store"}).then(() => {});
        // fetch('/dance-battle/init').then(() => {});
        // init('/chaos-characters/init', setChaosCharactersReady);
        // init('/dancelestrations/init', setDancelestrationsReady);
        // init('/make-it-a-dance/init', setMakeItADanceReady);
        // init('/mind-read/init', setMindReadReady);
        init('/chat/init', setChatReady);
        init('/trilemma/init', setTrilemmaReady);
        init('/dance-stop/init', setDanceStopReady);
        init('/chaos-quest/init', setChaosQuestReady);
    }

    const init = (path, setter) => {
        setter('🕚')

        fetch(path).then((response) => {
            if (response.ok) setter('✅');
            else setter('❌');
        }).catch(() => {
            setter('❌');
        });
    }

    return <div className="evenly-distribute">
        <div>
            <button style={{fontSize: 80}} onClick={() => initAll()}>Init</button>
        </div>
        <div>
            <div style={{fontSize: 34}}>{chatReady} Chat</div>
            <div style={{fontSize: 34}}>{trilemmaReady} Trilemma</div>
            <div style={{fontSize: 34}}>{danceStopReady} Dance Stop</div>
            <div style={{fontSize: 34}}>{chaosQuestReady} Chaos Quest</div>
        </div>
    </div>
}