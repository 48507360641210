import {usePreset} from "./osc";
import React, {useEffect, useRef, useState} from "react";

export const HighScores = ({isControl, scoresEndpoint, title}) => {
    const [highScores, setHighScores] = useState();

    usePreset('HighScores');

    useEffect(() => {
        fetch(scoresEndpoint)
            .then(response => response.json())
            .then(setHighScores)
    }, []);

    if(isControl) return <HighScoresControl title={title} highScores={highScores} />
    else return <HighScoresPlayer title={title} highScores={highScores} />;
}

const HighScoresPlayer = ({title, highScores}) => {
    const renderScores = (scores) =>
        scores.map((score) =>
            <div className={`highscores-${score.rank}`} style={{
                margin: 8,
                marginTop: 0,
                padding: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{textAlign: 'right'}}>
                    <div style={{fontSize: 36, width: '36px'}}>{score.rank}.</div>
                </div>
                <img style={{marginLeft: '10px', marginRight: '10px', boxShadow: '0 0 3px #000, 0 0 5px #000'}} src={score.character.image_url} height={90}
                     width={90}/>
                <div style={{textAlign: 'left'}}>
                    <div style={{fontSize: 22, fontWeight: 'bold'}}>{score.character.name}</div>
                    <div style={{fontSize: 18, fontWeight: 'bold'}}>{score.character.nickname}</div>
                </div>
                <div style={{flex: 1, fontSize: 36, textAlign: 'right'}}>
                    {score.score}
                </div>
            </div>
        )

    if(!highScores) return null;

    return <div style={{position: 'absolute', top: 0, width: '100%'}}>
        <div className={'highscores-title'} style={{fontSize: 40, marginTop: 4, marginBottom: 16, textAlign: 'center'}}>{title}</div>
        <div className={'evenly-distribute'} style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <div style={{width: '100%'}}>{renderScores(highScores.slice(0, 3))}</div>
            <div style={{width: '100%'}}>{renderScores(highScores.slice(3, 5))}</div>
        </div>
    </div>
}

export const HighScoresControl = ({title, highScores}) => {
    const renderScores = (scores) =>
        scores.map((score) =>
            <div key={score.character.token} className={`highscores-${score.rank}`} style={{
                margin: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '146px'
            }}>
                <div style={{textAlign: 'right', paddingRight: 30, width: 60}}>
                    <div style={{fontSize: 56}}>{score.rank}.</div>
                </div>
                <img style={{boxShadow: '0 0 3px #000, 0 0 5px #000'}} src={score.character.image_url} height={132} width={132}/>
                <div style={{paddingLeft: 10, flex: 1, textAlign: 'left'}}>
                    <div style={{fontSize: 40}}>{score.character.name}</div>
                    <div style={{fontSize: 40, fontWeight: 'bold'}}>{score.character.nickname}</div>
                </div>
                <div style={{fontSize: 86, margin: 18, textAlign: 'right' }}>
                    {score.score}
                </div>
            </div>
        )

    if(!highScores) return null;

    return <div style={{textAlign: 'center', fontSize: 80, flexDirection: 'column', width: '1500px', height: '900px', display: 'flex', alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'flex-start'}}>
        <div className={'highscores-title'} style={{margin: 2}}>{title}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
            <div style={{flex: 1, margin: 10}}>{renderScores(highScores.slice(0, 5))}</div>
            <div style={{flex: 1, margin: 10}}>{renderScores(highScores.slice(5, 10))}</div>
        </div>
    </div>
}

export const ScoreCounter = ({ score, highlightColor }) => {
    const [displayScore, setDisplayScore] = useState(score);
    const [isChanging, setIsChanging] = useState(false);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (displayScore !== score) {
            setIsChanging(true);
            timeoutRef.current = setTimeout(() => {
                setDisplayScore(prevScore => {
                    if (prevScore < score) {
                        return Math.min(prevScore + 1, score);
                    } else if (prevScore > score) {
                        return Math.max(prevScore - 1, score);
                    }
                    return score;
                });
            }, 50);
        } else {
            setIsChanging(false);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [displayScore, score]);

    const textStyle = {
        color: isChanging && highlightColor ? highlightColor : 'inherit',
        transition: 'color 0.3s ease'
    };

    return <span style={textStyle}>{displayScore}</span>;
};