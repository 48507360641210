import {useCallback, useEffect, useRef, useState} from 'react'

const useSocket = ({token, clockDrift}) => {
  const [socket, setSocket] = useState();
  const [scene, setScene] = useState(0);
  const [roundEnd, setRoundEnd] = useState(0);
  const subscribersRef = useRef(new Set());

  const sendScene = (sceneNumber) => socket.send(JSON.stringify({scene: sceneNumber, timestamp: Date.now() + clockDrift}));
  const sendRoundEnd = (roundEnd) => socket.send(JSON.stringify({roundEnd}));

  const newSocket = () => {
    if(!token) return;
    const socket = new WebSocket("wss://" + window.location.host + "?token=" + token);

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log(data)
      if(data.scene !== undefined) {
        setScene(data);
      } else if (data.roundEnd !== undefined) {
        setRoundEnd(data.roundEnd);
      } else {
        subscribersRef.current.forEach(callback => callback(data));
      }
    };

    setSocket(socket);
  }

  useEffect(() => {
    newSocket();
  }, [token]);

  useEffect(() => {
    return () => { if(socket) socket.close() };
  }, [socket]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if(document.hidden) return;
      if(!socket || socket.readyState !== WebSocket.OPEN || WebSocket.CONNECTING) {
        newSocket();
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, []);

  const receiveEvents = useCallback((callback) => {
    subscribersRef.current.add(callback);
    return () => subscribersRef.current.delete(callback);
  }, [subscribersRef]);

  return [scene, sendScene, roundEnd, sendRoundEnd, receiveEvents];
}

export default useSocket
