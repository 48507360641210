import React, {useEffect, useState} from 'react';

const getSecondsUntil = (targetDate) =>
    Math.max(
        0,
        Math.floor((new Date(targetDate) - new Date()) / 1000)
    )

const Countdown = ({ targetDate }) => {
    const [secondsLeft, setSecondsLeft] = useState(getSecondsUntil(targetDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft(getSecondsUntil(targetDate));
        }, 500);

        return () => clearInterval(timer);
    }, [targetDate]);

    return <span>{secondsLeft}</span>;
};

export default Countdown;