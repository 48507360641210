import {useEffect, useState} from "react";
import {usePreset} from "../../common/osc";

export const DisplayMindReadCard = () => {
    const [card, setCard] = useState();

    useEffect(() => {
        fetch('/mind-read/get-card', { credentials: 'include' })
            .then(response => response.json())
            .then(setCard);
    }, []);

    if(!card) return;

    const helperText = card.example ?
        <span>Think of <b>{card.example}</b> very clearly in your mind</span> :
        <span>Think of a specific <b>{card.value}</b> very clearly in your mind.</span>;

    return <div style={{
        border: '1px solid white',
        backgroundColor: '#1e0264',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 20
    }}>
        <span style={{padding: 10, alignSelf: 'flex-start'}}>
            <div style={{fontSize: 20}}>JFD</div>
        </span>
        <span style={{flex: 1, padding: 30}}>
            <div style={{fontSize: 70}}>{card.value}</div>
            <div style={{fontSize: 20}}>{card.example}</div>
        </span>
        <span style={{padding: 30}}>
            <div style={{fontSize: 20}}>{helperText}</div>
        </span>
        <span style={{padding: 10, alignSelf: 'flex-end'}}>
            <div style={{fontSize: 20}}>JFD</div>
        </span>
    </div>
}

export const MindRead = () => {
    usePreset('AI');
}