import {Canvas, useFrame, useLoader, useThree} from "@react-three/fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import React, {Suspense, useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {OrbitControls} from "@react-three/drei";
import {ErrorBoundary} from "../../common/errorboundary";

const Model = ({url, isUserInteracting}) => {
    const gltf = useLoader(GLTFLoader, url);
    const modelRef = useRef()
    const {scene} = useThree()

    useEffect(() => {
        if (gltf) {
            // Center and scale the model
            const box = new THREE.Box3().setFromObject(gltf.scene)
            const center = box.getCenter(new THREE.Vector3())
            gltf.scene.position.sub(center)

            const size = box.getSize(new THREE.Vector3())
            const maxDim = Math.max(size.x, size.y, size.z)
            const scale = 2 / maxDim
            gltf.scene.scale.multiplyScalar(scale)

            // Set metallicFactor of all materials to 0
            gltf.scene.traverse((node) => {
                if (node.isMesh) {
                    const materials = Array.isArray(node.material) ? node.material : [node.material]
                    materials.forEach((material) => {
                        if (material.isMeshStandardMaterial) {
                            material.metalness = 0.0
                        }
                    })
                }
            })

            scene.updateMatrixWorld(true)
        }
    }, [gltf, scene, url])

    useFrame((state, delta) => {
        if (modelRef.current && !isUserInteracting) {
            modelRef.current.rotation.y += delta * 0.5
        }
    })

    return <primitive ref={modelRef} object={gltf.scene}/>
}


const ControllableModel = ({url, setSwipeableDisabled}) => {
    const [isUserInteracting, setIsUserInteracting] = useState(false);
    const controlsRef = useRef();

    useEffect(() => {
        const controls = controlsRef.current;
        if (controls) {
            const handleStart = () => {
                setIsUserInteracting(true);
                setSwipeableDisabled(true); // Disable swiping
            };
            const handleEnd = () => {
                setIsUserInteracting(false);
                setSwipeableDisabled(false); // Re-enable swiping
            };

            controls.addEventListener('start', handleStart);
            controls.addEventListener('end', handleEnd);

            return () => {
                controls.removeEventListener('start', handleStart);
                controls.removeEventListener('end', handleEnd);
            };
        }
    }, [url, setSwipeableDisabled]);

    return (
        <>
            <OrbitControls
                ref={controlsRef}
                enablePan={false}
                enableZoom={false}
                enableRotate={true}
                minDistance={1}
                maxDistance={10}
            />
            <Model url={url} isUserInteracting={isUserInteracting}/>
        </>
    );
};

export const GLBViewer = ({url, setSwipeableDisabled}) => {
    return (
        <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <Canvas camera={{position: [0, 0, 3], fov: 50}}>
                <ambientLight intensity={1}/>
                <pointLight position={[10, 10, 10]} intensity={1}/>
                <pointLight position={[-10, -10, -10]} intensity={1}/>
                <directionalLight position={[0, 0, 5]} intensity={1}/>
                <Suspense fallback={null}>
                    <ControllableModel url={url} setSwipeableDisabled={setSwipeableDisabled}/>
                </Suspense>
            </Canvas>
        </div>
    )
}

export const ItemBox = ({
                            setSwipeableDisabled,
                            url,
                            name,
                            description,
                            borderColor,
                            backgroundColor,
                            giveCode,
                            giveText,
                            isOwner,
                            itemNumber
                        }) => (
    <div style={{
        width: '96%',
        border: `2px solid ${borderColor}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '15px',
        backgroundColor: backgroundColor,
        margin: 'auto'
    }}>
        <div style={{textAlign: 'center', color: '#FFFFFF', fontWeight: 'bold', fontSize: '24px'}}>{name}</div>
        <div style={{
            display: 'flex',
        }}>
            <div style={{width: '150px', height: '150px', margin: 'auto'}}>
                <ErrorBoundary fallback={<span/>}>
                    <GLBViewer url={url} setSwipeableDisabled={setSwipeableDisabled}/>
                </ErrorBoundary>
            </div>
            <div>
                <p style={{textAlign: 'center', color: '#FFFFFF', paddingRight: 4}}>{description}</p>
            </div>
        </div>
        <div style={{textAlign: 'center', padding: 4, fontWeight: 'bold'}}>
        </div>
        <div style={{
            textAlign: 'center',
            backgroundColor: borderColor,
            fontWeight: 'bold',
            width: '100%',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px'
        }}>
            {isOwner && itemNumber > 0 ? <>
                <div style={{padding: 4}}>You got it back!</div>
            </> : <>
                <div style={{padding: 4}}>{giveText}</div>
                <div style={{fontSize: 30}}>{giveCode}</div>
            </>
            }

        </div>
    </div>
);