import {TextScene} from "../../common/text";
import React, {useCallback, useEffect, useState} from "react";
import {clearAllTimeouts} from "../../common/helpers";
import {usePreset} from "../../common/osc";
import {usePolling} from "../../common/effects";

export const ChaosCharacters = ({round, isControl, hurryUp}) => {
    usePreset(hurryUp ? 'HurryUp' : 'Display');

    if(isControl) return <ChaosCharactersTally round={round} />
    else return <ChaosCharactersPlayer round={round} />;
}

const ChaosCharactersPlayer = ({round}) => {
    const [target, setTarget] = useState();
    const [score, setScore] = useState();
    const [answer, setAnswer] = useState("");

    const getScore = useCallback(() => {
        fetch('/chaos-characters/get-score', {credentials: 'include'})
            .then(response => response.json())
            .then(setScore)
    }, []);
    usePolling(getScore, 3000);

    const fetchTarget = () => {
        fetch('/chaos-characters/get-target?' + new URLSearchParams({round}), {credentials: 'include'})
            .then(response => response.json())
            .then(setTarget)
    }

    useEffect(() => {
        setTarget(null);
        fetchTarget()
    }, [round]);

    const getPrompt = () => {
        if(!target) return null;
        switch(round) {
            case 0:
                return <span>What is your nickname?</span>
            case 1:
                return <span>What is {target.target_one.name}'s nickname?</span>
            case 2:
                return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}><span>What is the nickname of the character with this photo?</span><img src={target.target_one.image_url} height='150' width='150'/></div>
            case 3:
                return <span>Tell {target.target_one.name} to submit {target.target_two?.name}'s nickname.</span>
            case 4:
                return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}><span>Tell {target.target_one.nickname} to submit the nickname of the character with this photo.</span><img src={target.target_two?.image_url} height='150' width='150'/></div>
        }
    }

    const submitAnswer = () => {
        setTarget(null);
        fetch('/chaos-characters/submit-answer', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({round, answer})
        }).then(() => {
            fetchTarget();
            getScore();
            setAnswer("");
        })
    }

    if (!target) return null;

    const getInteractive = () => {
        if(!target.answer) return <div style={{padding: 4, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{position: 'relative'}}>
                <input type="text" className="inputbox" style={{textTransform: 'capitalize'}} placeholder="Answer" value={answer} onChange={(event) => setAnswer(event.target.value)} />
                <span className="focus-border"><i /></span>
            </div>
            <button
                style={{
                    border: '1px white solid',
                    padding: 10,
                    fontWeight: 400,
                    fontSize: 30,
                    color: 'white',
                    backgroundColor: 'black'
                }}
                onClick={submitAnswer}>Submit</button>
        </div>;

        return <div style={{fontSize: 40}}>
            <div>{target.answer} {target.correct ? "✅" : "❌"}</div>
        </div>
    }

    return <div style={{flex: 1, textAlign: 'center', width: '100%', height: '100%'}}>
        <div className='evenly-distribute'>
            <div>
                <div style={{fontSize: 24, paddingBottom: 10}}>You Are:</div>
                <div style={{fontSize: 20}}>
                    <div style={{
                        border: '2px solid white',
                        backgroundColor: '#151515',
                        padding: 8,
                        margin: 8,
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        justifyContent: 'center',
                    }}>
                        <img src={target.you.image_url} height='130' width='130'/>
                        <div style={{paddingLeft: 10}}>
                            <div style={{fontSize: 28}}>{target.you.name}</div>
                            <div style={{fontSize: 20, fontWeight: 'bold'}}>{target.you.nickname}</div>
                            <div style={{fontSize: 20, paddingTop: 10}}>Score: {score?.score}</div>
                            <div style={{fontSize: 20}}>Rank: {score?.rank}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{fontSize: 30, padding: 16}}>{getPrompt()}</div>
                {getInteractive()}
            </div>
        </div>
    </div>
}

const ChaosCharactersTally = ({round}) => {
    const [results, setResults] = useState();

    const pollResults = () => {
        fetch('/chaos-characters/get-results')
            .then(response => response.json())
            .then((results) => {
                setResults(results);
                setTimeout(pollResults, 2000);
            })
    }

    useEffect(() => {
        pollResults();
        return clearAllTimeouts;
    }, []);

    if (!results) return null;

    const scores = results.correct_answers.map((answers, index) => {
        if (round >= index) {
            return <>
                <div style={{width: '200px', color: '#87ff00'}}>{results.correct_answers[index]}</div>
                <div style={{width: '200px', color: '#ff0000'}}>{results.incorrect_answers[index]}</div>
                <div style={{width: '200px'}}>{results.total - results.correct_answers[index] - results.incorrect_answers[index]}</div>
                <div style={{width: '200px'}}>{Math.round((results.correct_answers[index] / results.total) * 100)}%</div>
            </>;
        } else {
            return '-';
        }
    })

    const roundStyle = (scoreRound) => {
        const style = {
            border: '1px solid white',
            padding: 10,
            margin: 6,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '75%'
        };

        if(scoreRound === round) {
            return { ...style, borderColor: '#b4ff9b', border: 'solid 5px' }
        } else {
            return style;
        }
    }

    return <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
        <div className="evenly-distribute-vertical" style={{paddingTop: 0, justifyContent: 'flex-start'}}>
            <div style={{width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 78, marginTop: 10}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '75%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{fontSize: 100, paddingRight: 30}}>🦹🏽</div>
                            <div style={{fontSize: 60}}>Chaos Characters</div>
                        </div>
                        <div className={`grade-${results.grade}`} style={{padding: 10}}>
                            <div style={{fontSize: 30}}>GRADE</div>
                            <div style={{fontWeight: 'bold', fontSize: 60}}>{results.grade}</div>
                        </div>
                    </div>
                    <div style={{fontSize: 36, width: '75%',display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10, margin: 10}}>
                        <div style={{width: '200px'}}></div>
                        <div style={{width: '200px'}}>Correct</div>
                        <div style={{width: '200px'}}>Incorrect</div>
                        <div style={{width: '200px'}}>No Answer</div>
                        <div style={{width: '200px'}}>% Correct</div>
                    </div>
                    <div style={roundStyle(0)}>
                        <div style={{width: '200px', fontSize: 46}}>Round 1</div>
                        {scores[0]}
                    </div>
                    <div style={roundStyle(1)}>
                        <div style={{width: '200px', fontSize: 46}}>Round 2</div>
                        {scores[1]}
                    </div>
                    <div style={roundStyle(2)}>
                        <div style={{width: '200px', fontSize: 46}}>Round 3</div>
                        {scores[2]}
                    </div>
                    <div style={roundStyle(3)}>
                        <div style={{width: '200px', fontSize: 46}}>Round 4</div>
                        {scores[3]}
                    </div>
                    <div style={roundStyle(4)}>
                        <div style={{width: '200px', fontSize: 46}}>Round 5</div>
                        {scores[4]}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export const GradeIcon = () => {

}

export const Grade = ({isControl}) => {
    usePreset('Display');

    const [results, setResults] = useState();

    useEffect(() => {
        fetch('/chaos-characters/get-results')
            .then(response => response.json())
            .then(setResults);
    }, []);

    if(!results) return null;

    if(isControl) return <div style={{textAlign: 'center', fontSize: 80, flexDirection: 'column', width: '1500px', height: '900px', display: 'flex', alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'center'}}>
        <div className={`grade-${results.grade}`} style={{textAlign: 'center',  padding: 10}}>
            <div style={{fontSize: 160}}>GRADE</div>
            <div style={{fontWeight: 'bold', fontSize: 320}}>{results.grade}</div>
        </div>
    </div>;

    return <div className={`grade-${results.grade}`} style={{textAlign: 'center', padding: 10}}>
        <div style={{fontSize: 40}}>GRADE</div>
        <div style={{fontWeight: 'bold', fontSize: 80}}>{results.grade}</div>
    </div>;
}

export const ChaosCharactersTitle = ({isControl, name, r, g, b}) => {
    usePreset('ChaosCharacters');
    return <TextScene isControl={isControl} text={<span className={'title-card'}>🦹🏽<br/>Chaos Characters</span>}/>;
}