import './App.css';
import useSocket from "./useSocket";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {GetReady, TextScene} from "./common/text";
import {CharacterCreator} from "./games/character_creator/creator";
import {closeOsc, connectOsc} from "./common/osc";
import {DisplayMindReadCard} from "./games/mind_read/mind-read";
import {ChaosCharacters, ChaosCharactersTitle, Grade} from "./games/chaos_characters/chaos";
import {Chat, DrawingChat} from "./games/chat/chat";
import {CoachPlayer, DanceRoutineStep, FullDanceRoutine, GenerateDanceRoutine} from "./games/dance_routine/routine";
import {AlmostReady, Blank, GetReadyDisplay, JFD3Title, Preset, TitleCard} from "./common/title";
import {Dancelestrations, ShowDancelestrations} from "./games/dancelestrations/dancelestrations";
import {DanceText, MakeItADanceRainbow, MakeItADanceRGB} from "./games/make_it_a_dance/make";
import {GetDownStories, WeGetDown, WeGetDownChorus} from "./games/we_get_down/get_down";
import {WebMidi} from "webmidi";
import {Ending} from "./common/ending";
import {AiDialog, ReceivingTransmission} from "./games/ai/ai";
import DanceStop from "./games/dance_stop/dance_stop";
import {HighScores} from "./common/scores";
import {Trilemma} from "./games/trilemma/trilemma";
import {useClockDrift} from "./common/useClockDrift";
import {ChaosQuest} from "./games/chaos_quest/quest";
import {Leaderboard} from "./games/leaderboard/leaderboard";

function JustFuckingDance() {
    const history = useHistory();
    const clockDrift = useClockDrift();
    const [ready, setReady] = useState(false);
    const [isControl, setIsControl] = useState(false);
    const [user, setUser] = useState({});
    const [scenes, setScenes] = useState();
    const [scene, sendScene, roundEnd, sendRoundEnd, receiveEvents] = useSocket({token: user.token, clockDrift});

    const sceneNumber = scene.scene;
    const getCurrentScene = () => {
        const currentScene = Array.isArray(scenes[sceneNumber]) ? scenes[sceneNumber][0] : scenes[sceneNumber];

        if (!currentScene) return;
        return React.cloneElement(
            currentScene,
            {isControl, timestamp: scene.timestamp, receiveEvents, clockDrift, roundEnd}
        );
    }

    const previousCountdownScene = useRef(sceneNumber);
    useEffect(() => {
        if(!isControl) return;
        const sceneOptions = Array.isArray(scenes[sceneNumber]) ? scenes[sceneNumber][1] : {};

        if(sceneOptions.duration && sceneNumber !== previousCountdownScene.current) {
            const roundEnd = Date.now() + sceneOptions.duration;
            console.log('Set round end: ', roundEnd);

            sendRoundEnd(roundEnd);
            previousCountdownScene.current = sceneNumber;
        }
    }, [scenes, isControl, sceneNumber, sendRoundEnd]);

    useEffect(() => {
        setScenes([
            [<TextScene text={user.name}/>, {midi: 'C-1'}],
            <Blank/>,
            <Preset name={'Test'}/>,
            <Blank/>,
            [<AlmostReady username={user.name}/>, {midi: 'C1'}],
            [<CharacterCreator name={user.name}/>, {midi: 'D1'}],
            <Blank/>,
            [<Preset name={'Warmup'}/>, {midi: 'E1'}],
            [<JFD3Title/>, {midi: 'F1'}],
            [<Preset name={'AI'}/>, {midi: 'G1'}],
            <AiDialog
                dialogUrl={'/character-creator/get-quips'}
                preDialog={[
                    'Welcome back, mates!',
                    'It\'s Just Fucking Dance.',
                    'Your mission?',
                    'Complete all tasks to the best of your abilities.',
                    'And most importantly, fucking dance.',
                    'Now, let’s introduce today\'s cast, bouncing in like kangaroos on the outback.'
                ]}
                postDialog={[
                    'Ok, enough of that.',
                    'Introducing our first game: Trilemma.',
                    'It\'s like an evolved Rock Paper Scissors.',
                    'Good luck, mate.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            [<TitleCard lines={['⚔️🪞🩹', 'TRILEMMA']} r={140} g={40} b={255}/>, {midi: 'A1'}],
            <Blank />,
            <GetReady />,
            <Trilemma gameNumber={0}/>,
            <TextScene text={<span className={'digital'}>TOURNAMENT<br/>START</span>}/>,
            <Trilemma gameNumber={1}/>,
            <HighScores title={'Trilemma'} scoresEndpoint={'/trilemma/get-scores'}/>,
            <Trilemma gameNumber={2}/>,
            <HighScores title={'Trilemma'} scoresEndpoint={'/trilemma/get-scores'}/>,
            <Trilemma gameNumber={3}/>,
            [<Blank/>, {midi: 'B1'}],
            <Chat room={'general'} placeholder={'Say Something...'}/>,
            <Blank/>,
            [<Preset name={'AI'}/>, {midi: 'C2'}],
            <AiDialog
                preDialog={[
                    'Ok, let\'s review the results of our first game.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Leaderboard round={1}/>,
            <AiDialog
                orbFromBottom={true}
                preDialog={[
                    'On to our next game!',
                    'Dance Stop.',
                    'Good luck, mates.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Blank/>,
            [<TitleCard lines={['🪩 DANCE 🪩', '🛑 STOP 🛑']} r={140} g={40} b={255}/>, {midi: 'D2'}],
            [<DanceStop shouldDance={true} showScores={false} />, {midi: 'D7'}],
            [<DanceStop shouldDance={false} showScores={true} />, {midi: 'E7'}],
            [<DanceStop shouldDance={true} showInfomercial={true} />, {midi: 'F7'}],
            [<DanceStop shouldDance={false} showScores={false} />, {midi: 'C7'}],
            <HighScores title={'Dance Stop'} scoresEndpoint={'/dance-stop/get-scores'}/>,
            [<Blank/>, {midi: 'E2'}],
            <DrawingChat room={'drawing'} />, 
            <Blank/>,
            [<Preset name={'AI'}/>, {midi: 'F2'}],
            <AiDialog
                preDialog={[
                    'Ok, let\'s check in on how we are doing.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Leaderboard round={2}/>,
            <AiDialog
                orbFromBottom={true}
                preDialog={[
                    'Ok mates, that\'s all!',
                    'Thanks for playing!',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <AiDialog
                preDialog={[
                    'Just kidding.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Blank />,
            [<TitleCard lines={['✨🔮✨', 'CHAOS', 'QUEST']} r={140} g={40} b={255}/>, {midi: 'G2'}],
            <Blank />,
            <GetReady />,
            [<ChaosQuest round={1} />, {duration: 80000}],
            <HighScores title={'Chaos Quest'} scoresEndpoint={'/chaos-quest/get-scores?round=1'}/>,
            [<ChaosQuest round={2} />, {duration: 130000}],
            <HighScores title={'Chaos Quest'} scoresEndpoint={'/chaos-quest/get-scores?round=2'}/>,
            [<ChaosQuest round={3} />, {duration: 229000}],
            <HighScores title={'Chaos Quest'} scoresEndpoint={'/chaos-quest/get-scores?round=3'}/>,
            <Blank />,
            [<Preset name={'AI'}/>, {midi: 'B2'}],
            <AiDialog
                preDialog={[
                    'Ok, now for our final results!',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Leaderboard round={3}/>,
            <TextScene text={<span className={'digital'}>Thanks<br/>For<br/>Playing</span>}/>,
            <HighScores scoresEndpoint={'/leaderboard/get-leaderboard?round=3'} title={'Final Scores'}/>,


            // JFD3
            [<TextScene text={user.name}/>, {midi: ''}],
            <Blank/>,
            <Preset name={'Test'}/>,
            <Blank/>,
            [<AlmostReady username={user.name}/>, {midi: 'C1'}],
            [<CharacterCreator name={user.name}/>, {midi: 'D1'}],
            <Blank/>,
            [<Preset name={'Warmup'}/>, {midi: 'E1'}],
            [<JFD3Title/>, {midi: 'F1'}],
            [<Preset name={'AI'}/>, {midi: 'G1'}],
            <AiDialog
                dialogUrl={'/character-creator/get-quips'}
                preDialog={[
                    'G\'day mate!',
                    'Welcome to Just Fucking Dance.',
                    'Your mission?',
                    'Complete all tasks to the best of your abilities.',
                    'And most importantly, fucking dance.',
                    'Before we start, let\'s take a look at our players.',
                    'Hmm, yeah... some interesting characters in here.',
                    'Real weird.'
                ]}
                postDialog={[
                    'Now let\'s get on with the game.',
                    'Starting with Dancelestrations.',
                    'Good luck, mate.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Blank/>,
            [<TitleCard name={<span>🖌🎥🕺️<br/>Dancelestrations</span>} r={140} g={40} b={255}/>, {midi: 'A1'}],
            <GetReadyDisplay/>,
            <Dancelestrations round={0}/>,
            <TextScene text={'🤩'}/>,
            <Dancelestrations round={1}/>,
            <TextScene text={'😅'}/>,
            <Dancelestrations round={2} showPair={true}/>,
            <Dancelestrations round={2} showPair={false}/>,
            <TextScene text={'🥳'}/>,
            <Dancelestrations round={3}/>,
            <TextScene text={'😰'}/>,
            <Dancelestrations round={4}/>,
            <Blank/>,
            [<TitleCard name={<span>↓️<br/>we get down</span>} r={255} g={255} b={255}/>, {midi: 'B1'}],
            <WeGetDown/>,
            <GetDownStories front={true}/>,
            <WeGetDownChorus rgb={[0, 255, 0]} media={'Green'} colorEffectEnabled={false}/>,
            <WeGetDownChorus rgb={[255, 255, 0]} media={'Yellow'} colorEffectEnabled={false}/>,
            <WeGetDownChorus rgb={[0, 0, 255]} media={'Blue'} colorEffectEnabled={false}/>,
            <WeGetDownChorus rgb={[255, 255, 255]} media={'White'} colorEffectEnabled={false}/>,
            <GetDownStories front={false}/>,
            <WeGetDownChorus rgb={[255, 0, 10]} bgRgb={[255, 0, 180]} media={'Green'} colorEffectEnabled={true}/>,
            <WeGetDownChorus rgb={[255, 0, 0]} media={'Yellow'} colorEffectEnabled={true}/>,
            <WeGetDownChorus rgb={[119, 0, 230]} media={'Blue'} colorEffectEnabled={true}/>,
            <WeGetDownChorus rgb={[0, 160, 10]} media={'White'} colorEffectEnabled={true}/>,
            <TitleCard name={<span>↓️<br/>we get down</span>} r={255} g={255} b={255}/>,
            <Blank/>,
            [<TextScene text={'Should we see'}/>, {midi: 'C2'}],
            <TextScene text={'What the people are saying?'}/>,
            <Chat room={'general'} placeholder={'Say Something...'}/>,
            <Blank/>,
            [<ChaosCharactersTitle/>, {midi: 'D2'}],
            <GetReadyDisplay/>,
            <ChaosCharacters round={0} hurryUp={false}/>,
            <ChaosCharacters round={0} hurryUp={true}/>,
            <HighScores title={'Chaos Characters'} scoresEndpoint={'/chaos-characters/get-high-scores'}/>,
            <ChaosCharacters round={1} hurryUp={false}/>,
            <ChaosCharacters round={1} hurryUp={true}/>,
            <HighScores title={'Chaos Characters'} scoresEndpoint={'/chaos-characters/get-high-scores'}/>,
            <ChaosCharacters round={2} hurryUp={false}/>,
            <ChaosCharacters round={2} hurryUp={true}/>,
            <HighScores title={'Chaos Characters'} scoresEndpoint={'/chaos-characters/get-high-scores'}/>,
            <ChaosCharacters round={3} hurryUp={false}/>,
            <ChaosCharacters round={3} hurryUp={true}/>,
            <HighScores title={'Chaos Characters'} scoresEndpoint={'/chaos-characters/get-high-scores'}/>,
            <ChaosCharacters round={4} hurryUp={false}/>,
            <ChaosCharacters round={4} hurryUp={true}/>,
            <HighScores title={'Chaos Characters'} scoresEndpoint={'/chaos-characters/get-high-scores'}/>,
            <Grade/>,
            <Blank/>,
            [<Preset name={'Break1'}/>, {midi: 'E2'}],
            <JFD3Title preset={'Break3'}/>,
            <Blank/>,
            [<Preset name={'Metal'}/>, {midi: 'F2'}],
            [<ShowDancelestrations/>, {midi: 'G2'}],
            [<TitleCard name={<span>👯‍♀️<br/>Collaborative Dance Routine</span>} r={255} g={0} b={0}/>, {midi: 'A2'}],
            <Blank/>,
            <TextScene text={'Together, we will create'}/>,
            <TextScene text={'The ultimate dance routine'}/>,
            <TextScene text={'Please suggest your best'}/>,
            <TextScene text={'Dance Move Ideas'}/>,
            <Chat room={'dance-routine'} placeholder={'Dance Move Idea'}/>,
            [<GenerateDanceRoutine/>, {midi: 'B2'}],
            <TextScene text={'Alright team'}/>,
            <TextScene text={'Let\'s see if we can figure out'}/>,
            <TextScene text={'How to dance this routine'}/>,
            <GetReady/>,
            <DanceRoutineStep step={1}/>,
            <DanceRoutineStep step={2}/>,
            <DanceRoutineStep step={3}/>,
            <DanceRoutineStep step={4}/>,
            <TextScene text={'Enough practice'}/>,
            <TextScene text={'This time for real'}/>,
            <TextScene text={'Everybody looking great out there'}/>,
            <TextScene text={'Show me what you\'ve got, team!'}/>,
            <GetReady/>,
            <FullDanceRoutine/>,
            <TextScene text={'Not bad...'}/>,
            <TextScene text={'Just needs a few minor adjustments'}/>,
            <CoachPlayer/>,
            <TextScene text={'One last time'}/>,
            <TextScene text={'It\'s worth everything'}/>,
            <TextScene text={'Here we go'}/>,
            <GetReady/>,
            <FullDanceRoutine/>,
            <TitleCard name={<span>👯‍♀️👯‍♂️<br/>Collaborative Dance Routine</span>} r={255} g={0} b={0}/>,
            <Blank/>,
            [<Preset name={'AI'}/>, {midi: 'C3'}],
            <AiDialog
                preDialog={[
                    'Yo, what\'s good, mate?',
                    'I hope you are enjoying Just Fucking Dance.',
                    'You have almost completed your mission.',
                    'For my next trick, I will deal you a Person, Place, or Thing card.',
                    'Do not show your phone to anyone else.',
                    'I repeat, do not show your phone to anyone else.',
                    'Ready?'
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <AiDialog
                PlayerView={<DisplayMindReadCard/>}
            />,
            <AiDialog
                preDialog={[
                    'Alright, I\'ve randomly dealt everybody a card.',
                    'I have no idea what is on them.',
                    'Think hard about your specific Person, Place, or Thing.',
                ]}
                PlayerView={<DisplayMindReadCard/>}
            />,
            <AiDialog
                preDialog={[
                    'Ok, is everyone thinking of their specific item?',
                    'Last chance to look at your card.',
                ]}
                PlayerView={<DisplayMindReadCard/>}
            />,
            <AiDialog
                dialogUrl={'/mind-read/read-mind'}
                preDialog={[
                    'Okay, mates, now I will read your minds.',
                    'Wow, this is a tough one.',
                    'I\'m not getting anything...',
                    'Let\'s see... I think maybe I\'ve got someone.',
                    'Yeah, just give me a second.'
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <AiDialog
                preDialog={[
                    'Now, back by popular demand, our final activity.',
                    'Good luck, mate.',
                ]}
                PlayerView={<ReceivingTransmission/>}
            />,
            <Blank/>,
            [<TitleCard name={<span>💡💃<br/>Make It A Dance</span>} r={100} g={255} b={60}/>, {midi: 'D3'}],
            <Preset name={'MakeItADance'}/>,
            <TextScene text="Final activity."/>,
            <TextScene text="It's easy."/>,
            <TextScene text="All you have to do"/>,
            <TextScene text="Is Make It A Dance"/>,
            <TextScene text="I'll go first"/>,
            <GetReady/>,
            <MakeItADanceRGB hex={'#00FF00'} round={0}/>,
            <DanceText hex={'#ff00b3'} text="Now"/>,
            <DanceText hex={'#f7ff00'} text="Your Turn"/>,
            <MakeItADanceRGB hex={'#FF4E00'} round={1}/>, //
            <TextScene text="Nice moves!"/>,
            <MakeItADanceRGB hex={'#00ffc3'} round={2}/>,
            <TextScene text="Smooth."/>,
            <MakeItADanceRGB hex={'#a100ff'} round={3}/>,
            <TextScene text="Are you a professional?"/>,
            <MakeItADanceRGB hex={'#0800ff'} round={4}/>,
            <TextScene text="🔥"/>,
            <MakeItADanceRGB hex={'#ff0303'} round={5}/>,
            <TextScene text="Looking Good!"/>,
            <DanceText hex={'#ffea00'} text="Wait"/>,
            <DanceText hex={'#ff00b3'} text="Almost"/>,
            <MakeItADanceRGB hex={'#FF4E00'} round={6}/>,
            <TextScene text="Interesting..."/>,
            <MakeItADanceRGB hex={'#2cb900'} round={7}/>,
            <TextScene text="😍"/>,
            <DanceText hex={'#000000'} text="Now for the finale"/>,
            <TextScene text="Everybody at the same time"/>,
            <GetReady/>,
            <MakeItADanceRainbow round={8}/>,
            <TextScene text={<span className={'digital'}>Thanks<br/>For<br/>Playing</span>}/>,
            <Blank/>,
            [<JFD3Title preset={'EndingJFD'}/>, {midi: 'E3'}],
            <Blank/>,
            [<Ending name={user.name}/>, {midi: 'F3'}],
        ])
    }, [user, isControl]);

    useEffect(() => {
        if (!isControl) return;
        document.title = 'JFD Control';
    }, [isControl]);

    useEffect(() => {
        if (!isControl) return;
        connectOsc({isControlUser: isControl});
        return () => closeOsc();
    }, [isControl]);

    useEffect(() => {
        if (!isControl) return;

        WebMidi
            .enable()
            .then(() => {
                if (WebMidi.inputs.length < 1) {
                    console.log("No midi device detected.");
                }

                WebMidi.octaveOffset = -1;
                WebMidi.inputs[0].channels[1].removeListener();
                WebMidi.inputs[0].channels[1].addListener("noteon", e => {
                    const note = e.note.identifier;
                    console.log(`Received ${note}`);

                    if (note === 'C0') {
                        sendScene(sceneNumber + 1);
                    } else {
                        const index = scenes.findIndex((scene) => Array.isArray(scene) && scene[1].midi === note);
                        if (index >= 0) {
                            sendScene(index);
                        } else {
                            console.log(`Attempted to trigger unknown scene ${note}`)
                        }
                    }
                });
            })
            .catch(err => alert(err));

        return () => {
            if (WebMidi.inputs[0]) WebMidi.inputs[0].channels[1].removeListener()
        };
    }, [isControl, sceneNumber]);

    useEffect(() => {
        fetch('/users/current', {
            credentials: 'include'
        }).then(response => response.json())
            .then(response => {
                setUser(response);
                setIsControl(response.token === 'control');
                setReady(true);
            }).catch(() => {
            history.push('/login');
        })
    }, [history]);

    if (!ready) return null;

    const showControls = () => {
        return <div style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            bottom: 50,
            textAlign: 'center',
            pointerEvents: 'none'
        }}
        >
            <span style={{
                padding: 12,
                margin: 12,
                cursor: 'pointer',
                pointerEvents: 'auto',
                fontSize: 20,
                border: '1px solid grey'
            }} onClick={() => sendScene(sceneNumber - 1)}>
                ←
            </span>
            <span style={{
                padding: 12,
                margin: 12,
                fontSize: 20,
                cursor: 'pointer',
                pointerEvents: 'auto',
                border: '1px solid grey'
            }} onClick={() => sendScene(sceneNumber + 1)}>
                →
            </span>
        </div>
    }

    return <div
        style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
        {getCurrentScene()}
        {isControl && showControls()}
    </div>;
}

export default JustFuckingDance;
