import './App.css';
import {useState} from "react";

const SignUp = () => {
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const submitAnswer = () => {
    setIsDisabled(true)

    fetch('/users/register', {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({name})
    }).then(response => response.json())
      .then((registration) => {
        setName("")
        setRegistration(registration)
      }).finally(() => {
        setIsDisabled(false)
      })
  }

  return <div className={'evenly-distribute-vertical'} style={{textAlign: 'center'}}>
      <div>
        <div style={{fontSize: 40, textDecoration: 'underline'}}>{registration.name}</div>
        <div style={{paddingTop: 10, fontSize: 40}}>justfucking.dance/</div>
        <div style={{fontSize: 40, color: '#36ff36'}}>{registration.token}</div>
      </div>
      <div>
        <div style={{position: 'relative'}}>
          <input type="text" className="inputbox" placeholder="Name" value={name}
                 onChange={(event) => setName(event.target.value)}/>
          <span className="focus-border">
            <i/>
          </span>
        </div>
        <button
            style={{
              border: '1px white solid',
              padding: 10,
              marginTop: 30,
              fontWeight: 400,
              fontSize: 30,
              color: 'white',
              backgroundColor: 'black'
            }}
            disabled={isDisabled}
            onClick={submitAnswer}>
          Submit
        </button>
      </div>
  </div>
}

export default SignUp;
