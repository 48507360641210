import osc from "osc/dist/osc-browser";
import {useEffect, useRef, useState} from "react";

let oscPort;
let isControl;
let isReady;

const send = ({address, type, value}) => {
    if(!isControl) return;
    if(!isReady) return;

    console.log('Sending OSC message: ', address, type, value)

    oscPort.send({
        address: address,
        args: [{type: type, value: value}]
    });
}

export const connectOsc = ({isControlUser}) => {
    isControl = isControlUser;

    oscPort = new osc.WebSocketPort({
        url: "ws://localhost:2345", // URL to your Web Socket server.
        metadata: true
    });

    console.log('Opening OSC connection...')
    oscPort.open();

    oscPort.on('ready', () => isReady = true);

    oscPort.on("message", function (oscMsg) {
        console.log("An OSC message just arrived!", oscMsg);
    });
}

export const closeOsc = () => {
    console.log('Closing OSC connection...')
    isReady = false;
    oscPort.close();
}

export const useOrbPosition = (startPosition, endPosition, duration = 2000) => {
    const [currentPosition, setCurrentPosition] = useState(startPosition);
    const animationRef = useRef(null);
    const startTimeRef = useRef(null);

    useEffect(() => {
        const animate = (timestamp) => {
            if (!startTimeRef.current) startTimeRef.current = timestamp;
            const elapsed = timestamp - startTimeRef.current;
            const progress = Math.min(elapsed / duration, 1);

            const easedProgress = easeInOutCubic(progress);
            const newPosition = startPosition + (endPosition - startPosition) * easedProgress;

            setCurrentPosition(newPosition);

            if (progress < 1) {
                animationRef.current = requestAnimationFrame(animate);
            }
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [startPosition, endPosition, duration]);

    useEffect(() => {
        // Send the current position to your OSC endpoint
        send({
            address: "/OSCQUERY/ControlSurface/OrbHeight",
            type: "f",
            value: currentPosition
        });
    }, [currentPosition]);

    // Easing function for smooth animation
    const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    return currentPosition;
};

export const useLightColor = (r, g, b) => {
    useEffect(() => {
        send({
            address: "/OSCQUERY/ControlSurface/MainLightsRGB",
            type: "r",
            value: {
                r: r,
                g: g,
                b: b,
                a: 1.0
            }
        });
    }, [r, g, b]);
}

export const useLightAlpha = (a) => {
    useEffect(() => {
        send({
            address: "/OSCQUERY/ControlSurface/MainLightsAlpha",
            type: "f",
            value: a
        });
    }, [a]);
}

export const useTitleColor = (r, g, b) => {
    useEffect(() => {
        setTimeout(() => {
            send({
                address: "/OSCQUERY/ControlSurface/TitleEffectRGB",
                type: "r",
                value: {
                    r: r,
                    g: g,
                    b: b,
                    a: 1.0
                }
            });
        }, 200);
    }, [r, g, b]);
}

export const useMedia = (media) => {
    useEffect(() => {
        send({
            address: "/OSCQUERY/ControlSurface/Media",
            type: "s",
            value: media
        });
    }, [media]);
}

export const usePreset = (name) => {
    useEffect(() => {
        send({
            address: `/OSCQUERY/PresetControl/${name}`,
            type: "i",
            value: 1
        });
    }, [name]);
}

export const useToggle = (name, enabled) => {
    useEffect(() => {
        send({
            address: `/OSCQUERY/ControlSurface/${name}`,
            type: "i",
            value: enabled ? 1 : 0
        });
    }, [enabled]);
}

