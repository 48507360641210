import React, {useEffect, useState} from "react";
import {CaptureDancingGif} from "../dancelestrations/gif";
import {TextScene} from "../../common/text";
import {useLightColor, usePreset} from "../../common/osc";

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const MakeItADanceRainbow = ({isControl, round}) => {
    usePreset('MakeItADanceRainbow');
    return <MakeItADance isControl={isControl} round={round} />;
}

export const MakeItADanceRGB = ({isControl, round, hex = '#000000'}) => {
    const rgb = hexToRgb(hex);
    useLightColor(rgb.r, rgb.g, rgb.b);

    return <MakeItADance isControl={isControl} round={round} hex={hex} />;
}

export const MakeItADance = ({isControl, round}) => {
    const [assignment, setAssignment] = useState();

    const updateAssignment = () => {
        const assignmentUrl = round === 8 ? '/make-it-a-dance/get-self-assignment?' : '/make-it-a-dance/get-spotlight-round-assignment?';

        fetch(assignmentUrl + new URLSearchParams({round}), {credentials: 'include'})
            .then(response => response.json())
            .then(setAssignment)
    }

    useEffect(() => {
        updateAssignment();
    }, [round]);

    if(!assignment) return null;
    if(isControl && round === 8) return null;
    if(isControl) return <MakeItADanceControl assignment={assignment} />;
    return <MakeItADancePlayer assignment={assignment} />;
}

export const DanceText = ({isControl, text, hex}) => {
    const rgb = hexToRgb(hex);
    useLightColor(rgb.r, rgb.g, rgb.b);

    return <TextScene isControl={isControl} text={text} />;
}

const MakeItADanceControl = ({assignment}) => {
    return <div style={{height: '900px', width: '1500px', textAlign: 'center', alignSelf: 'flex-start', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{margin: 40, fontSize: 80}}>{assignment.value}</div>
        <div style={{
                border: '2px solid white',
                backgroundColor: '#151515',
                padding: 8,
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <img src={assignment.character.image_url} width={300} />
                <div style={{fontSize: 60, paddingTop: 10}}>{assignment.character.name}</div>
                <div style={{fontSize: 40, fontWeight: 'bold'}}>{assignment.character.nickname}</div>
            </div>
        </div>
}

const MakeItADancePlayer = ({assignment}) => {
    return <div className='evenly-distribute'>
        <div style={{margin: 20, fontSize: 40, textAlign: 'center'}}>{assignment.value}</div>
        <div style={{
            border: '2px solid white',
            backgroundColor: '#151515',
            padding: 8,
            width: '210px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <img src={assignment.character.image_url} width={200} />
            <div style={{fontSize: 40, paddingTop: 10}}>{assignment.character.name}</div>
            <div style={{fontSize: 28, fontWeight: 'bold'}}>{assignment.character.nickname}</div>
        </div>
    </div>
}