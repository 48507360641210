import {useCallback, useEffect, useState} from "react";
import {clearAllTimeouts} from "./helpers";

function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const UserRoulette = () => {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        fetch('/users')
            .then(response => response.json())
            .then((users) => nextUser(0, users))

        return clearAllTimeouts;
    }, []);

    const nextUser = (current, users) => setTimeout(() => {
        setCurrentUser(users[(current) % users.length].name);
        nextUser(current + 1, users);
    }, 80)

    if (!currentUser) return null;

    return <div style={{height: '100%', width: '100%', textAlign: 'center', alignItems: 'stretch'}}>
        <div className="evenly-distribute" style={{alignItems: 'stretch'}}>
            <div style={{fontWeight: 'bolder'}} className="evenly-distribute">
                <div>
                    <div style={{fontSize: 36}}>Selecting Teams...</div>
                    <div style={{fontSize: 80}}>{currentUser}</div>
                </div>
            </div>
        </div>
    </div>;
}
const discoColors = [
    '#00eaf2',
    '#0db704',
    '#feea03',
    '#fa7c06',
    '#fc02ff',
    '#012dcc',
    '#560697',
    '#fa0101'
]
export const DiscoScene = () => {
    useEffect(() => {
        document.body.style.background = discoColors[discoColors.length * Math.random() | 0];
        nextColor()
        return () => {
            clearAllTimeouts();
            document.body.style.background = 'black';
        };
    }, [])

    const nextColor = () => setTimeout(() => {
        document.body.style.background = discoColors[discoColors.length * Math.random() | 0];
        nextColor();
    }, 2000)

    return null;
}
export const RedScene = () => {
    let currentTimeout;

    useEffect(() => {
        turnRedOn()
        return () => {
            clearAllTimeouts();
            document.body.style.background = 'black';
        };
    }, [])

    const turnRedOn = () => setTimeout(() => {
        document.body.style.background = '#ff0000';
        currentTimeout = setTimeout(turnRedOff, 1500);
    }, random(1, 5) * 100)

    const turnRedOff = () => setTimeout(() => {
        document.body.style.background = 'black';
        currentTimeout = setTimeout(turnRedOn, random(15, 30) * 100);
    })

    return null;
}
export const Wait = () => {
    return <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
        <div className="evenly-distribute" style={{justifyContent: "center"}}>
            <div style={{padding: 30, marginRight: 30, marginLeft: 30}}>
                <div style={{fontSize: 110, textAlign: 'center'}}>⚠️</div>
            </div>
            <div style={{color: '#fff'}}>
                <div style={{fontSize: 90}} className='blink-text'>WAIT</div>
            </div>
        </div>
    </div>;
}

export const usePolling = (func, interval) => {
    useEffect(() => {
        func();

        const pollingFunction = () => setTimeout(() => {
            func();
            pollingFunction();
        }, interval);
        pollingFunction();

        return clearAllTimeouts;
    }, [func, interval]);
}
