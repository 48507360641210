import {usePreset, useTitleColor} from "./osc";
import {GetReady, TextScene} from "./text";
import React from "react";

export const JFD3Title = ({isControl, preset='JFD'}) => {
    usePreset(preset);
    if(isControl) return <img style={{alignSelf: 'flex-start'}} src='title.jpg' />;
    return <img style={{width: '100%', height: '100%', objectFit: 'contain'}} src='title.jpg' />
}

export const Title = () => {
    return <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
        <div className="evenly-distribute" style={{justifyContent: "center"}}>
            <div style={{color: '#fff'}}>
                <div style={{fontSize: 70}}>JUST</div>
                <div style={{fontSize: 70}}>FUCKING</div>
                <div style={{fontSize: 70}}>DANCE</div>
            </div>
            <div style={{
                padding: 10,
                marginLeft: 4,
                marginRight: 4,
                backgroundSize: '150% 90%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'url(flame-bg.gif)'
            }}>
                <div className="two" style={{fontSize: 220, textAlign: 'center'}}>2</div>
            </div>
        </div>
    </div>;
}

export const TitleNoTwo = () => {
    return <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
        <div className="evenly-distribute" style={{justifyContent: "center"}}>
            <div style={{color: '#fff'}}>
                <div style={{fontSize: 70}}>JUST</div>
                <div style={{fontSize: 70}}>FUCKING</div>
                <div style={{fontSize: 70}}>DANCE</div>
            </div>
            <div style={{padding: 10, marginLeft: 4, marginRight: 4}}>
                <div style={{color: '#000', fontSize: 220, textAlign: 'center'}}>2</div>
            </div>
        </div>
    </div>;
}

export const TitleCard = ({isControl, lines, r, g, b}) => {
    usePreset('Title');
    useTitleColor(r, g, b);
    return <TextScene isControl={isControl} lines={lines} textStyle={{fontFamily: "'Press Start 2P', cursive"}} />;
}

export const Preset = ({name}) => {
    usePreset(name);
    return <div/>;
}

export const Blank = () => {
    usePreset('Display');
    return <div/>;
}

export const AlmostReady = ({isControl, username}) => {
    usePreset('Intro');
    return <TextScene isControl={isControl} text={isControl ? 'Almost Ready' : username}/>;
}

export const GetReadyDisplay = ({isControl}) => {
    usePreset('Display');
    return <GetReady isControl={isControl}/>;
}
