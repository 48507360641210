import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import SignUp from './SignUp';
import Login from './Login';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Init} from "./init";
import JustFuckingDance from "./JustFuckingDance";
import {Notify} from "./Notify";

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
    <BrowserRouter>
        <Switch>
            <Route path="/init"><Init/></Route>
            <Route path="/login"><Login/></Route>
            <Route path="/register"><SignUp/></Route>
            <Route path="/notify"><Notify/></Route>
            <Route path="/"><JustFuckingDance/></Route>
        </Switch>
    </BrowserRouter>
);
