import React, {useCallback, useEffect, useState} from "react";
import {clearAllTimeouts} from "../../common/helpers";
import {usePreset} from "../../common/osc";
import {usePolling} from "../../common/effects";
import {create} from "simple-drawing-board";

export const Chat = ({isControl, room, placeholder, receiveEvents}) => {
    usePreset('Display');
    if (isControl) return <ChatDisplay room={room} receiveEvents={receiveEvents} />
    else return <ChatPlayer placeholder={placeholder} room={room} />;
}

export const DrawingChat = ({isControl, room, receiveEvents}) => {
    usePreset('Display');
    if (isControl) return <DrawingChatDisplay room={room} receiveEvents={receiveEvents}/>
    else return <DrawingChatPlayer room={room} />;
}

function isCanvasBlank(canvas) {
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(
        context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some(color => color !== 0);
}

export const DrawingChatPlayer = ({room}) => {
    const [sdb, setSdb] = useState();
    const [selectedColor, setSelectedColor] = useState('#FFFFFF');

    useEffect(() => {
        const sdb = create(document.getElementById("drawing"));
        sdb.setLineSize(10);
        sdb.setLineColor('white');
        setSdb(sdb);

        return () => {
            sdb.clear();
            sdb.destroy();
        };
    }, []);

    const updateSelectedColor = (color) => {
        setSelectedColor(color);
        sdb.setLineColor(color);
    }

    const renderColor = (color) => {
        let style = {width: 20, height:20, backgroundColor: color};
        if(selectedColor === color) {
            style = {...style, border: '3px white solid'};
        } else {
            style = {...style, border: '3px black solid'};
        }

        return <div onClick={() => updateSelectedColor(color)} style={style}></div>
    }

    const sendDrawing = () => {
        const canvas = document.getElementById('drawing');
        if(isCanvasBlank(canvas)) return;

        fetch('/chat/send-chat', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({content: sdb.toDataURL(), room})
        }).then(() => {
            sdb.clear();
        });
    }

    return <div
        id='drawing-container'
        style={{height: '100%', width: '100%'}}>
        <div style={{textAlign: 'center'}}>
            <button onClick={() => sdb.clear()} style={{padding: 10, marginBottom: 50}}>Clear</button>
            <button onClick={() => sdb.undo()} style={{padding: 10, marginLeft: 50}}>Undo</button>
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                {renderColor('#FFFFFF')}
                {renderColor('#EE204D')}
                {renderColor('#FFDC00')}
                {renderColor('#1F75FE')}
                {renderColor('#B5674D')}
                {renderColor('#FF7538')}
                {renderColor('#1CAC78')}
                {renderColor('#925EAE')}
            </div>
        </div>
        <canvas id="drawing" style={{border: '3px dotted white'}} width="500" height="500"/>
        <button onClick={sendDrawing} style={{padding: 10}}>Send</button>
    </div>;
}

const ChatPlayer = ({placeholder, room}) => {
    const [content, setContent] = useState("");

    const submitAnswer = () => {
        fetch('/chat/send-chat', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({content, room})
        }).then(() => {
            setContent("");
        })
    }

    return <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{position: 'relative'}}>
        <input type="text" className="inputbox" placeholder={placeholder} value={content} onChange={(event) => setContent(event.target.value)} />
        <span className="focus-border"><i /></span>
    </div>
    <button
        style={{
            border: '1px white solid',
            padding: 10,
            margin: 30,
            fontWeight: 400,
            fontSize: 30,
            color: 'white',
            backgroundColor: 'black'
        }}
        onClick={submitAnswer}>Send</button>
</div>
}

const DrawingChatDisplay = ({room, receiveEvents}) => {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        fetch(`/chat/get-chats?room=${room}`)
            .then(response => response.json())
            .then((chats) => {
                setChats(chats.slice(-8))
            });
    }, [])

    useEffect(() => {
        return receiveEvents((event) => {
            setChats(currentChats => {
                const existingChats = currentChats.length >= 8
                    ? currentChats.slice(-4)
                    : currentChats;
                return [...existingChats, event];
            });

            new Audio('/notification.wav').play();
        });
    }, []);

    useEffect(() => {
        const sdbs = [];

        chats.forEach((chat) => {
            const sdb = create(document.getElementById(`drawing-${chat.id}`));
            sdb.clear();
            sdb.fillImageByDataURL(chat.content);

            sdbs.push(sdb);
        })

        return () => {
            sdbs.forEach((sdb) => sdb.destroy());
        };
    }, [chats]);

    console.log(chats);

    return <div style={{
        fontSize: 80,
        flexDirection: 'column',
        width: '1500px',
        height: '900px',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        justifyContent: 'center',
    }}>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', paddingLeft: 100, paddingTop: 8}}>
            {chats.map((chat, index) =>
                <div key={chat.id} style={{
                    width: '330px',
                    margin: '8px',
                    backgroundColor: 'black',
                    border: '1px solid white',
                }}>
                    <canvas id={`drawing-${chat.id}`} width="330" height="330"/>
                    <div style={{display: 'flex', background: '#313131', padding: 4, paddingLeft: 8, borderTop: '1px solid white'}}>
                        <img src={chat.image_url} height={70} width={70}/>
                        <div style={{color: 'white', paddingLeft: 8}}>
                            <div style={{fontSize: 28}}>{chat.name}</div>
                            <div style={{fontSize: 24, fontWeight: 'bold', width: 240}}>{chat.nickname}</div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    </div>
}

const ChatDisplay = ({room, receiveEvents}) => {
    const [chats, setChats] = useState([]);

    useEffect(() => {
        fetch(`/chat/get-chats?room=${room}`)
            .then(response => response.json())
            .then(setChats);
    }, [])

    useEffect(() => {
        return receiveEvents((event) => {
            setChats((prevChats) => [...prevChats, event])
            new Audio('/notification.wav').play();
        });
    }, []);

    useEffect(() => {
        const chatWindow = document.getElementById('chatWindow');
        chatWindow.scrollTop = chatWindow.scrollHeight;
    }, [chats])

    const renderChats = () => {
        return chats.map((chat) =>
            <div style={{ margin: 10, display: 'flex', alignItems: 'center'}}>
                <img src={chat.image_url} height={150} width={150}/>
                <div style={{paddingLeft: 10, height: '100%'}}>
                    <div style={{fontSize: 34}}>{chat.name}</div>
                    <div style={{fontSize: 34, fontWeight: 'bold', width: 240}}>{chat.nickname}</div>
                </div>
                <div className='bubble bubble-left' style={{ flex: 1, fontSize: 30, margin: 20 }}>
                    {chat.content}
                </div>
            </div>
        )
    }

    return <div style={{display: 'flex', flexDirection: 'column', height: '900px', width: '75%', alignSelf: 'flex-start'}}>
        <div id='chatWindow' style={{color: 'white', fontSize: 100, flex: 1, marginTop: 10, marginBottom: 10, border: '3px dashed #a2a2a2', overflowY: 'hidden'}}>
            {renderChats()}
        </div>
    </div>
}
