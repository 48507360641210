import React, {useEffect, useState} from "react";
import {TextScene} from "../../common/text";
import {usePreset} from "../../common/osc";

export const GenerateDanceRoutine = ({isControl}) => {
    usePreset('CollaborativeDanceRoutine')

    const [status, setStatus] = useState('');

    useEffect(() => {
        if(!isControl) return;
        setStatus('🕐')

        fetch(`/dance-routine/generate-dance-routine`)
            .then(() => {
                setStatus('✅')
            })
    }, []);

    return <TextScene isControl={isControl} text={`Reviewing Ideas... ${status}`} />
}

export const CoachPlayer = ({isControl}) => {
    const [player, setPlayer] = useState();

    useEffect(() => {
        fetch(`/dance-routine/get-coached-player`)
            .then((result) => result.json())
            .then((json) => {
                setPlayer(json.name)
            })
    }, []);

    if(!player) return;
    return <TextScene isControl={isControl} text={`Get those knees up ${player}`} />
}

export const DanceRoutineStep = ({isControl, step}) => {
    const [routine, setRoutine] = useState();

    useEffect(() => {
        if(!isControl) return;
        fetch(`/dance-routine/get-dance-routine`)
            .then(response => response.json())
            .then(setRoutine)
    }, [])

    if(!isControl || !routine) return <TextScene text={'~collaborative dance routine~'} />;

    return <div style={{height: '900px', width: '1500px', textAlign: 'center', alignSelf: 'flex-start', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{fontSize: 50}}>Step {step}</div>
        <div style={{paddingTop: 20, fontSize: 70, fontWeight: 'bold'}}>{routine[step - 1]}</div>
    </div>
}

export const FullDanceRoutine = ({isControl}) => {
    const [routine, setRoutine] = useState();

    useEffect(() => {
        if(!isControl) return;
        fetch(`/dance-routine/get-dance-routine`)
            .then(response => response.json())
            .then(setRoutine)
    }, [])

    if(!isControl) return <TextScene text={'~collaborative dance routine~'} />;
    if(!routine) return <div />;

    return <div style={{height: '900px', width: '1500px', textAlign: 'center', alignSelf: 'flex-start', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{padding: 20}}>
            <div style={{fontSize: 45}}>Step 1</div>
            <div style={{paddingTop: 2, fontSize: 50, fontWeight: 'bold'}}>{routine[0]}</div>
        </div>
        <div style={{padding: 20}}>
            <div style={{fontSize: 45}}>Step 2</div>
            <div style={{paddingTop: 2, fontSize: 50, fontWeight: 'bold'}}>{routine[1]}</div>
        </div>
        <div style={{padding: 20}}>
            <div style={{fontSize: 45}}>Step 3</div>
            <div style={{paddingTop: 2, fontSize: 50, fontWeight: 'bold'}}>{routine[2]}</div>
        </div>
        <div style={{padding: 20}}>
            <div style={{fontSize: 45}}>Step 4</div>
            <div style={{paddingTop: 2, fontSize: 50, fontWeight: 'bold'}}>{routine[3]}</div>
        </div>
    </div>
}
