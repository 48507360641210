import {Wave} from "react-animated-text";
import React, {useEffect, useRef, useState} from "react";

export const TextScene = ({isControl, text, lines, textStyle}) => {
    if(isControl) {
        return <div style={{
            ...textStyle,
            textAlign: 'center',
            width: '1500px',
            height: '900px',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            justifyContent: 'center'
        }}>
            <div style={{fontSize: '160px'}}>{text ? text : lines.map((line) => <div>{line}</div>)}</div>
        </div>
    }

    return <div style={{flex: 1, textAlign: 'center', width: '100%'}}>
        <FullWidthText lines={text ? [text] : lines} textStyle={textStyle} maxFontSize={70} />
    </div>
}

export const GetReady = ({isControl}) => {
    return <TextScene isControl={isControl} text={<Wave text="Get Ready..." effect="color" effectChange={'#262626'}/>}/>;
}

const FullWidthText = ({ lines = [], maxFontSize = 500, textStyle = {} }) => {
    const containerRef = useRef(null);
    const lineRefs = useRef([]);
    const [fontSizes, setFontSizes] = useState([]);

    const calculateFontSize = (textRef, containerWidth) => {
        if (!textRef) return 0;

        let low = 1;
        let high = maxFontSize;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            textRef.style.fontSize = `${mid}px`;

            if (textRef.offsetWidth <= containerWidth) {
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }

        return Math.min(high, maxFontSize);
    };

    useEffect(() => {
        lineRefs.current = lineRefs.current.slice(0, lines.length);
    }, [lines]);

    useEffect(() => {
        const resizeText = () => {
            if (!containerRef.current) return;

            const containerWidth = containerRef.current.offsetWidth;

            const newFontSizes = lineRefs.current.map(ref =>
                calculateFontSize(ref, containerWidth)
            );

            setFontSizes(newFontSizes);
        };

        resizeText();
        window.addEventListener('resize', resizeText);

        return () => {
            window.removeEventListener('resize', resizeText);
        };
    }, [lines, maxFontSize, textStyle]);

    const combinedTextStyle = (fontSize) => ({
        ...textStyle,
        fontSize: `${fontSize}px`,
        whiteSpace: 'nowrap',
    });

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {lines.map((line, index) => (
                <div
                    key={index}
                    ref={el => lineRefs.current[index] = el}
                    style={combinedTextStyle(fontSizes[index] || 100)}
                >
                    {line}
                </div>
            ))}
        </div>
    );
};
