import {useCallback, useEffect, useState} from 'react';

const fetchServerTime = async () => {
    const response = await fetch('/time');
    const data = await response.json();
    return parseInt(data.time, 10);
};

const calculateClockDrift = async () => {
    const clientSendTime = Date.now();
    const serverReceiveTime = await fetchServerTime();
    const clientReceiveTime = Date.now();

    const roundTripTime = clientReceiveTime - clientSendTime;
    const estimatedServerTime = serverReceiveTime + (roundTripTime / 2);
    const drift = estimatedServerTime - clientReceiveTime;

    console.log('Clock Drift: ', drift);
    return drift;
};

export const useClockDrift = (syncInterval = 60000) => {
    const [clockDrift, setClockDrift] = useState(0);

    const syncClockWithRTT = useCallback(async () => {
        const drift = await calculateClockDrift();
        setClockDrift(drift);
    }, []);

    useEffect(() => {
        setTimeout(syncClockWithRTT, 1000);
        const intervalId = setInterval(syncClockWithRTT, syncInterval);
        return () => clearInterval(intervalId);
    }, [syncClockWithRTT, syncInterval]);

    useEffect(() => {
        const onVisibilityChange = () => {
            if(document.hidden) return;
            setTimeout(syncClockWithRTT, 1000);
        }

        document.addEventListener('visibilitychange', onVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange)
        }
    }, []);

    return clockDrift;
}
