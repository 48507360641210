import React, {useEffect, useState} from "react";
import {create} from "simple-drawing-board";
import {CaptureDancingGif} from "./gif";
import {TextScene} from "../../common/text";
import {usePreset} from "../../common/osc";
import {clearAllTimeouts} from "../../common/helpers";

export const Dancelestrations = ({isControl, round, showPair = false}) => {
    const [task, setTask] = useState();

    const updateTask = () => {
        fetch('/dancelestrations/get-task?' + new URLSearchParams({round}), {credentials: 'include'})
            .then(response => response.json())
            .then(setTask)
    }

    useEffect(() => {
        if(isControl) return;
        updateTask();
    }, [round]);

    if(isControl) return <DancelestrationsAdmin isControl={isControl} round={round} showPair={showPair} />;

    if(!task) return null;
    if(task.task === 'draw') return <Draw word={task.word} saveDataType={task.save_data_type} existing={task.existing} />;
    if(task.task === 'guess-drawing') return <GuessDrawing drawing={task.drawing} saveDataType={task.save_data_type} existing={task.existing} updateTask={updateTask} />;
    if(task.task === 'take-dance-gif' && showPair) return <Pair pair={task.pair} />;
    if(task.task === 'take-dance-gif') return <CaptureDancingGif pair={task.pair} guess={task.your_guess} existing={task.existing} updateTask={updateTask} />;
    if(task.task === 'guess-dance') return <GuessDance danceGifUrl={task.dance_gif_url} saveDataType={task.save_data_type} existing={task.existing} updateTask={updateTask} />;

    return <div></div>;
}

const DancelestrationsAdmin = ({isControl, round, showPair}) => {
    let scene, preset;

    switch (round) {
        case 0:
            preset = 'Draw';
            scene = <TextScene isControl={isControl} text={<span style={{fontWeight: 'bold', fontSize: '180px'}}>draw!</span>} />;
            break;
        case 1:
            preset = 'Guess';
            scene = <TextScene isControl={isControl} text={<span style={{fontWeight: 'bold', fontSize: '180px'}}>guess!</span>} />;
            break;
        case 2:
            preset = showPair ? 'Find' : 'Create';
            scene = <TextScene isControl={isControl} text={<span style={{fontWeight: 'bold', fontSize: '180px'}}>{showPair ? `find!` : `create!`}</span>} />;
            break;
        case 3:
            preset = 'Guess';
            scene = <TextScene isControl={isControl} text={<span style={{fontWeight: 'bold', fontSize: '180px'}}>guess!</span>} />;
            break;
        case 4:
            preset = 'Draw';
            scene = <TextScene isControl={isControl} text={<span style={{fontWeight: 'bold', fontSize: '180px'}}>draw!</span>} />;
            break;
    }

    usePreset(preset);
    return scene;
}

const GuessDance = ({danceGifUrl, saveDataType, existing, updateTask}) => {
    const [guess, setGuess] = useState('');

    const submitAnswer = () => {
        if(guess === '') return;
        fetch('/dancelestrations/save-data', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({dataType: saveDataType, value: guess})
        }).then(updateTask)
    }

    const getInputArea = () => {
        if (existing) {
            return <p style={{fontSize: 40, textAlign: 'center'}}>You guessed: <strong>{existing}</strong></p>
        } else {
            const title = danceGifUrl ? 'What is it?' : <span>No Animation :(<br/><br/>Guess Anyway?</span>
            return <div className="evenly-distribute-vertical" style={{textAlign: 'center'}}>
                <p style={{fontSize: 40}}>{title}</p>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <input type="text" className="inputbox" placeholder="Answer" value={guess}
                           onChange={(event) => setGuess(event.target.value)}/>
                <button
                    style={{
                        border: '1px white solid',
                        fontWeight: 400,
                        fontSize: 30,
                        color: 'white',
                        backgroundColor: 'black'
                    }}
                    onClick={submitAnswer}>Submit
                </button>
                </div>
            </div>
        }
    }

    return <div
        id='drawing-container'
        style={{height: '100%', width: '100%'}}>
        <img className={'rotational-scale'} style={{margin: 20}} src={danceGifUrl} />
        {getInputArea()}
    </div>;
}

const Pair = ({pair}) => {
    return <div className='evenly-distribute'>
        <div style={{margin: 20, fontSize: 40}}>Find Your Pair</div>
        <div style={{
            border: '2px solid white',
            backgroundColor: '#151515',
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <img src={pair.image_url} width={250} />
            <div style={{fontSize: 40, paddingTop: 10}}>{pair.name}</div>
            <div style={{fontSize: 28, fontWeight: 'bold'}}>{pair.nickname}</div>
        </div>
    </div>
}

const GuessDrawing = ({drawing, saveDataType, existing, updateTask}) => {
    const [guess, setGuess] = useState('');

    useEffect(() => {
        const sdb = create(document.getElementById("drawing"));

        if(drawing) {
            sdb.fillImageByDataURL(drawing);
        } else {
            sdb.clear();
        }

        return () => sdb.destroy();
    }, [drawing]);

    const submitAnswer = () => {
        fetch('/dancelestrations/save-data', {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({dataType: saveDataType, value: guess})
        }).then(updateTask)
    }

    const getInputArea = () => {
        if (existing) {
            return <p style={{fontSize: 40, textAlign: 'center'}}>You guessed: <strong>{existing}</strong></p>
        } else {
            return <p className="evenly-distribute-vertical">
                <p style={{fontSize: 40}}>What is it?</p>
                <div>
                    <input type="text" className="inputbox" placeholder="Answer" value={guess}
                           onChange={(event) => setGuess(event.target.value)}/>
                </div>
                <button
                    style={{
                        border: '1px white solid',
                        padding: 10,
                        marginTop: 30,
                        fontWeight: 400,
                        fontSize: 30,
                        color: 'white',
                        backgroundColor: 'black'
                    }}
                    onClick={submitAnswer}>Submit
                </button>
            </p>
        }
    }

    return <div
        id='drawing-container'
        style={{height: '100%', width: '100%'}}>
        <canvas id="drawing" width="500" height="500"/>
        {getInputArea()}
    </div>;
}

const Draw = ({word, saveDataType, existing}) => {
    const [subject, setSubject] = useState();
    const [sdb, setSdb] = useState();
    const [selectedColor, setSelectedColor] = useState('#FFFFFF');

    useEffect(() => {
        const sdb = create(document.getElementById("drawing"));
        sdb.setLineSize(10);
        sdb.setLineColor('white');

        if(existing) {
            sdb.fillImageByDataURL(existing);
        }

        if(word) {
            setSubject(<span>Draw <b>{word}</b></span>);
        } else {
            setSubject(<span>Draw Anything!</span>);
        }
        setSdb(sdb);

        return () => {
            fetch('/dancelestrations/save-data', {
                method: 'post',
                credentials: 'include',
                body: JSON.stringify({dataType: saveDataType, value: sdb.toDataURL()})
            }).then(response => console.log(response))
                .catch(error => console.log(error))

            sdb.clear();
            sdb.destroy();
        };
    }, [word, existing]);

    const updateSelectedColor = (color) => {
        setSelectedColor(color);
        sdb.setLineColor(color);
    }

    const renderColor = (color) => {
        let style = {width: 20, height:20, backgroundColor: color};
        if(selectedColor === color) {
            style = {...style, border: '3px white solid'};
        } else {
            style = {...style, border: '3px black solid'};
        }

        return <div onClick={() => updateSelectedColor(color)} style={style}></div>
    }

    return <div
        id='drawing-container'
        style={{height: '100%', width: '100%'}}>
        <div style={{textAlign: 'center'}}>
            <p style={{fontSize: 40, textAlign: 'center'}}>{subject}</p>
            <button onClick={() => sdb.clear()} style={{padding: 10, marginBottom: 50}}>Clear</button>
            <button onClick={() => sdb.undo()} style={{padding: 10, marginLeft: 50}}>Undo</button>
            <div style={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                {renderColor('#FFFFFF')}
                {renderColor('#EE204D')}
                {renderColor('#FFDC00')}
                {renderColor('#1F75FE')}
                {renderColor('#B5674D')}
                {renderColor('#FF7538')}
                {renderColor('#1CAC78')}
                {renderColor('#925EAE')}
            </div>
        </div>
        <canvas id="drawing" style={{border: '3px dotted white'}} width="500" height="500"/>
    </div>;
}

export const ShowDancelestrations = ({isControl}) => {
    if(isControl) return <ShowDancelestrationsControl />;
    else return <TextScene text={'~dancelestrations~'} />;;
}

const ShowDancelestrationsControl = () => {
    usePreset('Display');

    const [index, setIndex] = useState(0);
    const [interval, setInterval] = useState();
    const [count, setCount] = useState();
    const [dancelestration, setDancelestration] = useState();

    useEffect(() => {
        fetch('/dancelestrations/get-dancelestrations-count')
            .then(response => response.json())
            .then((json) => {
                const totalSeconds = 220;
                const count = Math.min(json.count, 20);

                setCount(count);
                setInterval(Math.round(totalSeconds / count) * 1000);
            });
    }, []);

    useEffect(() => {
        if(!interval) return;
        if(index >= count) return;

        fetch('/dancelestrations/get-dancelestration?' + new URLSearchParams({index}))
            .then(response => response.json())
            .then(setDancelestration);

        setTimeout(() => {
            setIndex(index + 1);
        }, interval);

        return clearAllTimeouts;
    }, [index, interval])

    useEffect(() => {
        if(!dancelestration) return;

        const sdb1 = create(document.getElementById("drawing-one"));
        const sdb2 = create(document.getElementById("drawing-two"));

        if (dancelestration.initial_drawing) {
            sdb1.fillImageByDataURL(dancelestration.initial_drawing);
        }
        if (dancelestration.dance_guess_drawing) {
            sdb2.fillImageByDataURL(dancelestration.dance_guess_drawing);
        }

        return () => {
            sdb1.destroy();
            sdb1.clear();
            sdb2.destroy();
            sdb2.clear();
        };
    }, [dancelestration])

    if(!dancelestration) return null;

    const getDancingDescription = () => {
        if(dancelestration.initial_drawing_guess && dancelestration.dancing_gif_url) {
            return <span style={{width: '500px'}}>{dancelestration.dancer.name} danced<br />{dancelestration.initial_drawing_guesser.name}'s guess:<br/><strong>{dancelestration.initial_drawing_guess}</strong></span>
        } else if(dancelestration.initial_drawing_guess && !dancelestration.dancing_gif_url) {
            return <span style={{width: '500px'}}>{dancelestration.initial_drawing_guesser.name} failed to capture<br />{dancelestration.dancer.name} dancing their guess: <br/><strong>{dancelestration.initial_drawing_guess}</strong></span>
        } else if(!dancelestration.initial_drawing_guess && dancelestration.dancing_gif_url) {
            return <span style={{width: '500px'}}>{dancelestration.initial_drawing_guesser.name} didn't guess, but<br />{dancelestration.dancer.name} danced anyway!</span>
        } else if(!dancelestration.initial_drawing_guess && !dancelestration.dancing_gif_url) {
            return <span style={{width: '500px'}}>{dancelestration.initial_drawing_guesser.name} was negligent.</span>
        }
    }

    if(index >= count) return;

    return <div style={{display: 'flex', alignSelf: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', width: '1500px', height: '900px', fontSize: 40, textAlign: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', height: '150px'}}>
            <span style={{width: '500px'}}>{dancelestration.initial_drawer.name} drew:<br/><strong>{dancelestration.initial_word}</strong></span>
            {getDancingDescription()}
            {dancelestration.dance_guess ?
                <span style={{width: '500px'}}>{dancelestration.dance_guess_drawer.name} drew<br />{dancelestration.dance_guesser.name}'s guess:<br/><strong>{dancelestration.dance_guess}</strong></span>
                :
                <span style={{width: '500px'}}>{dancelestration.dance_guesser.name} didn't guess!<br/>{dancelestration.dance_guess_drawer.name} drew anyway.</span>
            }
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', flex: 1}}>
            <canvas className="drawing-story" id="drawing-one" width={'500px'} height={'500px'} style={{width: '468px', height: '468px', border: '6px double', borderColor: '#FFFFFF'}} />
            <div style={{width: '480px', margin: '20px'}}>
                {dancelestration.dancing_gif_url ?
                    <img style={{width: '468px', maxHeight: '700px', objectFit: 'contain', border: '6px double', borderColor: '#FFFFFF'}} src={dancelestration.dancing_gif_url} />
                    :
                    <div style={{width: '468px', height: '468px', border: '6px dotted', borderColor: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No<br />Animation<br/>:(</div>
                }
            </div>
            <canvas className="drawing-story" id="drawing-two" width={'500px'} height={'500px'} style={{width: '468px', height: '468px', border: '6px double', borderColor: '#FFFFFF'}}  />
        </div>
    </div>;
}