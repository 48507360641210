import React, {useEffect, useRef, useState} from 'react';
import {HighScores, HighScoresControl, ScoreCounter} from "../../common/scores";
import {ItemBox} from "./items";
import SwipeableViews from 'react-swipeable-views';
import Countdown from "../../common/countdown";


const CodeBar = ({getQuestLog}) => {
    const [code, setCode] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [response, setResponse] = useState(null);
    const timeout = useRef()

    let statusColor, codeBorder;
    if (!response) {
        statusColor = '#131313';  // Gold
        codeBorder = '#ffffff';   // DarkGoldenRod
    } else if (response.success) {
        statusColor = '#00b900';  // LimeGreen
        codeBorder = '#004b00';   // ForestGreen
    } else {
        statusColor = '#de0000';  // OrangeRed
        codeBorder = '#640000';   // DarkRed
    }

    useEffect(() => {
        setResponse(null);

        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
            setCode([]);
        }, 3000);

        if (code.length === 5) {
            setIsSubmitting(true);
            fetch('/chaos-quest/submit-code', {
                method: 'post',
                credentials: 'include',
                body: JSON.stringify({code: code.join('')})
            })
                .then(response => response.json())
                .then((response) => {
                    setIsSubmitting(false);
                    setResponse(response);
                    getQuestLog();
                    setTimeout(() => {
                        setCode(prevCode => prevCode === code ? [] : prevCode);
                    }, 3000);
                })
        }
    }, [code]);

    const pressButton = (button) => {
        if (isSubmitting) return; // Prevent button presses during submission

        setCode(prev => {
            const newCode = [...prev, button];
            return newCode.length > 5 ? [button] : newCode;
        });
    }

    return (
        <>
            <style>
                {`
                  @keyframes shake {
                    0% { padding-left: 40px; padding-right: 40px; }
                    25% { padding-left: 30px; padding-right: 50px; }
                    50% { padding-left: 40px; padding-right: 40px; }
                    75% { padding-left: 50px; padding-right: 30px; }
                    100% { padding-left: 40px; padding-right: 40px; }
                  }
                `}
            </style>
            <div style={{
                color: 'white',
                position: 'absolute',
                zIndex: 1000,
                bottom: 130,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                transition: 'all 200ms',
                width: '100%',
                textShadow: `1px 1px 0 ${codeBorder}, -1px -1px 0 ${codeBorder}, 1px -1px 0 ${codeBorder}, -1px 1px 0 ${codeBorder}`,
                boxShadow: code.length > 0 ? `inset 0 -60px 10px -8px ${statusColor}` : 'inset 0 -20px 10px -8px black',
                animation: response?.success === false ? 'shake 0.2s ease-in-out' : 'none',
            }}>
                {code.map((s, i) => <span key={i} style={{fontSize: '52px', display: 'inline-block'}}>{s}</span>)}
            </div>
            <div style={{
                width: '100%',
                height: '100px',
                paddingBottom: '30px',
                backgroundColor: 'black',
                borderTop: '1px solid white',
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                zIndex: 1000,
                fontSize: '54px',
            }}>
                {
                    response?.success ? <div style={{fontSize: '20px', display: 'flex', alignItems: 'center'}}>
                        <img src={response.imageUrl} height={'50px'} width={'50px'}/>
                        <div style={{paddingLeft: '8px'}}>Obtained the <b>{response.name}</b>!</div>
                    </div> : <>
                        <CodeButton character={'🌹'} name={' ROSE'} pressButton={pressButton}/>
                        <CodeButton character={'🐝'} name={'BEE'} pressButton={pressButton}/>
                        <CodeButton character={'🐊'} name={'CROC'} pressButton={pressButton}/>
                        <CodeButton character={'🥏'} name={'DISC'} pressButton={pressButton}/>
                        <CodeButton character={'🍇'} name={'GRAPE'} pressButton={pressButton}/>
                    </>
                }
            </div>
        </>
    );
};

const CodeButton = ({character, name, pressButton}) => {
    const [isPressed, setIsPressed] = useState(false);

    const handlePressStart = () => setIsPressed(true);
    const handlePressEnd = () => setIsPressed(false);

    return <div
        onClick={() => pressButton(character)}
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={handlePressEnd}
        onTouchStart={handlePressStart}
        onTouchEnd={handlePressEnd}
        onTouchCancel={handlePressEnd}
        style={{
            fontWeight: isPressed ? 'bold' : 'normal',
        }}
    >
        <div style={{
            textShadow: isPressed ? `2px 2px 0 white, -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white` : 'none',
        }}>{character}</div>
        <div style={{fontSize: 16, textAlign: 'center'}}>{name}</div>
    </div>
}

export const ChaosQuest = ({isControl, round, roundEnd, receiveEvents}) => {
    if (isControl) return <ChaosQuestControl round={round} receiveEvents={receiveEvents}/>;
    return <ChaosQuestPlayer round={round} receiveEvents={receiveEvents} roundEnd={roundEnd}/>;
}

const ChaosQuestControl = ({round, receiveEvents}) => {
    const [highScores, setHighScores] = useState();

    const fetchScores = () => {
        fetch('/chaos-quest/get-scores?' + new URLSearchParams({round}), {credentials: 'include'})
            .then(response => response.json())
            .then(setHighScores)
    }

    useEffect(fetchScores, [round]);
    useEffect(() => receiveEvents(fetchScores), []);

    return <HighScoresControl title={'Chaos Quest'} highScores={highScores}/>;
}

const ChaosQuestPlayer = ({round, roundEnd, receiveEvents}) => {
    const [questLog, setQuestLog] = useState();
    const [index, setIndex] = useState(0);
    const [swipeableDisabled, setSwipeableDisabled] = useState(false);

    const getQuestLog = () => {
        fetch('/chaos-quest/get-quest-log?' + new URLSearchParams({round}), {credentials: 'include'})
            .then(response => response.json())
            .then(setQuestLog)
    }
    useEffect(getQuestLog, [round])
    useEffect(() => receiveEvents(getQuestLog), []);

    if (!questLog) return;

    const styles = {
        root: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
        },
        page: {
            height: 'calc(100vh)', // Assuming CodeBar is 50px high
            overflowY: 'auto',
        },
        pagination: {
            zIndex: 2000,
            position: 'absolute',
            bottom: '10px', // Place it just above the CodeBar
            width: '100%',
            textAlign: 'center',
        },
    };

    return <>
        <HUD roundEnd={roundEnd} score={questLog.score}/>
        <CodeBar getQuestLog={getQuestLog}/>
        <div style={styles.root}>
            <SwipeableViews disabled={swipeableDisabled} index={index} onChangeIndex={setIndex} enableMouseEvents>
                <div style={styles.page}>
                    <PlayerHeader round={round} questLog={questLog}/>
                    <div style={{padding: 16, paddingBottom: '300px'}}>
                        <div style={{
                            fontSize: 28,
                            marginBottom: 18,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderBottom: '1px solid white',
                        }}>
                            <span style={{fontSize: 34}}>Q</span>UESTS ⏵
                        </div>
                        {questLog.quests.map((quest, key) => <div key={key} style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: 12
                        }}>︎
                            <div style={{fontSize: 38, marginRight: 12}}>{quest.completed ? '✔︎' : '☐'}</div>
                            <div
                                style={{
                                    fontSize: 22,
                                    textDecoration: quest.completed ? 'line-through' : 'none'
                                }}>{quest.description} {round > 2 && quest.progress && <b>[{quest.progress}]</b>}</div>
                        </div>)}
                    </div>
                </div>
                <div style={styles.page}>
                    <PlayerHeader round={round} questLog={questLog}/>
                    <div style={{padding: 16, paddingBottom: '300px'}}>
                        <div style={{
                            fontSize: 28,
                            marginBottom: 18,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderBottom: '1px solid white',
                        }}>
                            ⏴ <span style={{fontSize: 34}}>C</span>OLLECTABLES ⏵
                        </div>
                        {questLog.collectables.map((item, key) => <div key={key} style={{
                            marginBottom: 12,
                            border: item.isDesired ? '1px solid #00c21a' : '1px solid grey',
                            background: item.isDesired ? 'linear-gradient(280deg, #000000,#000000, #004a0a)' : 'linear-gradient(280deg, #000000,#000000, #00024a)',
                            display: 'flex',
                            alignItems: 'center'
                        }}>︎
                            <div style={{
                                paddingLeft: 10,
                                fontSize: 22,
                                fontWeight: 'bold',
                                flex: 1
                            }}>
                                <div>{item.name}</div>
                                {!item.isDesired && <div style={{fontSize: 28}}>{item.giveCode}</div>}
                            </div>
                            {item.quantity > 1 && <div style={{padding: 8, fontSize: 30}}>{item.quantity}x</div>}
                            <img src={item.imageUrl} width={'80'} height={'80'}/>
                        </div>)}
                    </div>
                </div>
                <div style={styles.page}>
                    <PlayerHeader round={round} questLog={questLog}/>
                    <div style={{padding: 16, paddingBottom: '300px'}}>
                        <div style={{
                            fontSize: 28,
                            marginBottom: 18,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            borderBottom: '1px solid white',
                        }}>
                            ⏴ <span style={{fontSize: 34}}>S</span>PECIAL <span style={{fontSize: 34}}>I</span>TEMS
                        </div>
                        {questLog.specialItems.map((item) => <div key={item.giveCode} style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: 12
                        }}>︎
                            <ItemBox
                                name={item.name}
                                url={item.modelUrl}
                                description={item.description}
                                borderColor={item.borderColor}
                                backgroundColor={item.backgroundColor}
                                giveCode={item.giveCode}
                                giveText={item.giveText}
                                isOwner={item.isOwner}
                                itemNumber={item.itemNumber}
                                setSwipeableDisabled={setSwipeableDisabled}
                            />
                        </div>)}
                    </div>
                </div>
            </SwipeableViews>
            <div style={styles.pagination}>
                <Pagination dots={3} index={index}/>
            </div>
        </div>
    </>
}

const Pagination = ({dots, index}) => {
    const dotStyle = (isActive) => ({
        height: '10px',
        width: '10px',
        margin: '0 5px',
        backgroundColor: isActive ? '#ccc' : '#717171',
        borderRadius: '50%',
        display: 'inline-block',
        transition: 'background-color 0.6s ease',
    });

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
        }}>
            {Array.from({length: dots}).map((_, i) => (
                <span
                    key={i}
                    style={dotStyle(index === i)}
                />
            ))}
        </div>
    );
};

const PlayerHeader = ({questLog, round}) => {
    return <div style={{
        textAlign: 'center',
        paddingTop: 46,
        width: '100%',
        background: 'linear-gradient(180deg, #000000, #3b3b3b)',
        paddingBottom: 4,
        borderBottom: '2px solid #525252',
    }}>
        <div className={'digital'} style={{
            margin: 4,
            padding: 10,
            fontWeight: 'bold',
            fontSize: 24,
            color: 'black',
            textShadow: `2px 4px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff`,
        }}>
            CHAOS QUEST
        </div>
        <div style={{
            width: '100%',
            display: 'flex',
            marginTop: 4,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
        }}>
            <div style={{marginRight: 8}}>
                <img src={questLog.player.image_url} style={{border: '1px solid grey'}} width={'120'}
                     height={'120'}/>
            </div>
            <div>
                <div style={{fontSize: '28px', fontWeight: 'bold'}}>{questLog.player.name}</div>
                <div style={{fontSize: '22px'}}>{questLog.player.nickname}</div>
                <div style={{paddingTop: 10}}>
                    <span style={{fontSize: 22, marginRight: 8}}>Round</span>
                    <span style={{fontSize: 22}}>{round}/3</span>
                </div>
            </div>
        </div>
    </div>
}

const HUD = ({roundEnd, score}) => {
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                color: 'white',
                position: 'absolute',
                fontWeight: 'bold',
                zIndex: 1000,
                fontSize: 30,
                padding: 2,
                width: 240,
                borderRadius: 20,
                border: '1px solid grey',
                top: 4,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                backgroundColor: 'black',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{width: 40}}><Countdown targetDate={roundEnd}/></span>
                    <span style={{fontSize: 16, marginLeft: 8}}>TIME</span>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{fontSize: 16, marginRight: 8}}>SCORE</span>
                    <span style={{width: 40}}><ScoreCounter highlightColor={'#00e800'} score={score}/></span>
                </div>
            </div>
        </>
    );
}