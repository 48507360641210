import {TextScene} from "../../common/text";
import React, {useEffect, useRef, useState} from "react";
import Typed from "typed.js";
import {useOrbPosition} from "../../common/osc";

export const ReceivingTransmission = () => {
    return <TextScene textStyle={{fontFamily: "'Press Start 2P', cursive"}} lines={['Receiving', 'Transmission']}/>
}

const TypedText = ({story, speed = 70}) => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [story],
            typeSpeed: speed,
            backSpeed: 10,
            smartBackspace: false,
            cursorChar: ' ■',
            startDelay: 400
        });

        return () => typed.destroy();
    }, [story]);

    return <span style={{fontSize: 64, backgroundColor: 'black', fontWeight: 'bold'}}><span ref={el}/></span>;
}

export const AiSpeech = ({isControl, text, completedCallback}) => {
    useEffect(() => {
        if (!isControl) return;
        if (!text) return;

        const url = "/text-to-speech?text=" + encodeURIComponent(text);
        const audio = new Audio(url);
        audio.play();

        audio.addEventListener('ended', () => {
            setTimeout(completedCallback, 500);
        });

        return () => audio.pause();
    }, [text, isControl])

    return <TypedText story={text} speed={18} />;
}

export const AiDialog = ({isControl = false, preDialog = [], postDialog = [], dialogUrl, PlayerView, orbFromBottom = false}) => {
    const [generatedDialog, setGeneratedDialog] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [index, setIndex] = useState(0);
    const [characterOne, setCharacterOne] = useState();
    const [characterTwo, setCharacterTwo] = useState();
    const characterHighlightPosition = useRef(0);

    const startOrbPosition = orbFromBottom ? 0.18 : 0.42;
    useOrbPosition(startOrbPosition, 0.42)

    let currentText;
    if (generatedDialog.length === 0) {
        currentText = preDialog[index];
    } else if (index < preDialog.length) {
        currentText = preDialog[index];
    } else if (index < preDialog.length + generatedDialog.length) {
        currentText = generatedDialog[index - preDialog.length];
    } else {
        currentText = postDialog[index - preDialog.length - generatedDialog.length];
    }

    const completedCallback = () => {
        setIndex(index + 1);
    }

    useEffect(() => {
        setIndex(0);
    }, [PlayerView]);

    useEffect(() => {
        if (!isControl) return;
        if (!dialogUrl) return;

        fetch(dialogUrl)
            .then(response => response.json())
            .then((json) => {
                const sentenceSplitter = new Intl.Segmenter('en', {
                    granularity: 'sentence'
                });

                setGeneratedDialog(
                    Array.from(
                        sentenceSplitter.segment(json['dialog']),
                        s => s.segment
                    )
                );

                setCharacters(json['characters']);
            });
    }, [isControl, dialogUrl]);

    useEffect(() => {
        if (!characters.length) return;

        const character = characters.find(character =>
            currentText.includes(character.name)
        );

        if(!character) {
            setCharacterOne(null);
            setCharacterTwo(null);
            return;
        };

        if(characterHighlightPosition.current === 0) {
            characterHighlightPosition.current = 1;
            setCharacterOne(character);
            setCharacterTwo(null);
        } else {
            setCharacterOne(null);
            characterHighlightPosition.current = 0;
            setCharacterTwo(character);
        }
    }, [currentText, characters]);

    if (!isControl) return PlayerView;
    if (!currentText) return;

    console.log(characterOne)
    console.log(characterTwo)

    const getCharacterHighlight = (position, character) => {
        return <div style={{
            position: 'absolute',
            [position]: character ? 155 : -300,
            opacity: character ? 1 : 0,
            transition: 'all 300ms',
            top: 290,
            width: 340,
            height: 580,
            border: '2px solid white',
            backgroundColor: '#151515',
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <img src={character?.image_url} height={300} width={300}/>
            <div style={{fontSize: 40, paddingTop: 10}}>{character?.name}</div>
            <div style={{fontSize: 34, fontWeight: 'bold'}}>{character?.nickname}</div>
            <div style={{fontSize: 24}}>{character?.interesting_fact}</div>
        </div>;
    };

    return <>
        <div style={{fontSize: 70, textAlign: 'center', width: '1500px', alignSelf: 'flex-start'}}>
            <AiSpeech text={currentText} isControl={isControl} completedCallback={completedCallback} />
        </div>
        {getCharacterHighlight('left', characterOne)}
        {getCharacterHighlight('right', characterTwo)}
    </>;
}