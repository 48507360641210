import React, {useCallback, useEffect, useRef, useState} from 'react';
import {usePolling} from "../../common/effects";
import {Wave} from "react-animated-text";
import {HighScoresControl, ScoreCounter} from "../../common/scores";
import {useLightColor, usePreset} from "../../common/osc";

const useFirstVisitReload = (key = 'hasVisited') => {
    useEffect(() => {
        const hasVisited = sessionStorage.getItem(key);

        if (!hasVisited) {
            console.log('hi')
            sessionStorage.setItem(key, 'true');
            window.location.reload();
        }
    }, []);
};

export const DanceStopScores = ({scoresEndpoint, title}) => {
    const [highScores, setHighScores] = useState();

    useEffect(() => {
        fetch(scoresEndpoint)
            .then(response => response.json())
            .then(setHighScores)
    }, []);

    return <HighScoresControl title={title} highScores={highScores} />
}

const Infomercial = ({ infomercial }) => {
    const [isIntro, setIsIntro] = useState(true);

    useEffect(() => {
        if (isIntro) {
            setTimeout(() => setIsIntro(false), 4000);
        }
    }, []);

    if(isIntro) return <div className={'dancestop'} style={{
        backgroundColor: '#006501',
        textAlign: 'center',
        display: 'flex',
        width: '1600px',
        height: '900px',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    }}>
        <div style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            textAlign: 'center',
            fontSize: 80,
        }}>DANCE!</div>
        <div style={{
            fontStyle: 'italic',
            fontSize: 180,
        }}>Introducing...</div>
    </div>;

    return (
        <div style={{
            boxShadow: 'inset 0px 0px 0px 30px #006501',
            textAlign: 'center',
            fontSize: 80,
            flexDirection: 'column',
            width: '1600px',
            height: '900px',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            justifyContent: 'flex-start',
            backgroundImage: `url(${infomercial.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <div style={{
                fontSize: '120px',
                fontWeight: 'bold',
                color: 'white',
                textShadow: `4px 4px 0 black, -4px -4px 0 black, 4px -4px 0 black, -4px 4px 0 black`,
            }}>
                The {infomercial.productName}!
            </div>
            <div style={{
                fontSize: '70px',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: 'white',
                flex: 1,
                textShadow: `4px 4px 0 black, -4px -4px 0 black, 4px -4px 0 black, -4px 4px 0 black`,
            }}>
                by {infomercial.character.name},<br/>the "{infomercial.character.nickname}"
            </div>
            <div style={{
                fontSize: '80px',
                fontStyle: 'italic',
                marginBottom: '30px',
                fontWeight: 'bold',
                color: '#fff',
                textShadow: `4px 4px 0 black, -4px -4px 0 black, 4px -4px 0 black, -4px 4px 0 black`,
            }}>
                "{infomercial.productTagline}"
            </div>
        </div>
    );
};

const DanceStop = ({isControl, shouldDance, showScores, showInfomercial, timestamp, clockDrift, receiveEvents}) => {
    const [infomercial, setInfomercial] = useState();
    usePreset('DanceStop');
    useLightColor(!shouldDance ? 255 : 0, shouldDance ? 255 : 0, 0);

    useEffect(() => {
        fetch('/dance-stop/get-infomercial', {credentials: 'include'})
            .then(response => response.json())
            .then(setInfomercial)
    }, []);

    if (isControl && infomercial && showInfomercial) return <Infomercial infomercial={infomercial} />
    if (isControl && showScores) return <DanceStopScores scoresEndpoint={'/dance-stop/get-scores'} title={'Current Scores'} />
    if (isControl) return <DanceStopControl shouldDance={shouldDance} receiveEvents={receiveEvents} since={timestamp} />

    return (
        <div style={{display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            <MotionPermissionWrapper shouldDance={shouldDance} since={timestamp} clockDrift={clockDrift} />
        </div>
    );
};

export default DanceStop;

const DanceStopControl = ({shouldDance, since, receiveEvents}) => {
    const [events, setEvents] = useState([]);
    const [eventQueue, setEventQueue] = useState([]);
    const [isClearing, setIsClearing] = useState(false);

    const fetchFromQueue = useCallback(() => {
        setEventQueue(currentQueue => {
            if (currentQueue.length === 0) return currentQueue;

            setEvents(currentEvents => {
                const newEvents = currentEvents.length >= 12
                    ? currentEvents.slice(6)
                    : currentEvents;
                return [...newEvents, currentQueue[0]];
            });

            if(!shouldDance) new Audio('/wrong.wav').play();
            return currentQueue.slice(1);
        });
    }, [shouldDance]);
    usePolling(fetchFromQueue, 150);

    useEffect(() => {
        setEventQueue([]);
        if(events.length) setEvents([]);
    }, [shouldDance, since])

    useEffect(() => {
        return receiveEvents((event) => {
            if(event.hit === 'success') setEvents((events) => [...events, event]);
            else setEventQueue((eventQueue) => [...eventQueue, event]);
        });
    }, []);

    useEffect(() => {
        setIsClearing(false);
        if(!events.length) return;

        let eventClearTimeout;
        const clearingTimeout = setTimeout(() => {
            setIsClearing(true);
            eventClearTimeout = setTimeout(() => {
                setEvents([]);
            }, 500)
        }, 2000);

        return () => {
            clearTimeout(clearingTimeout);
            if(eventClearTimeout) clearTimeout(eventClearTimeout);
        }
    }, [events]);

    const addEvent = () => {
        setEventQueue([...eventQueue, {character: {image_url: 'https://storage.googleapis.com/character-pics/fathersday-pandemonium-1720302641.png', name: 'Joey', nickname: 'Staphylococcus Warden'}, hit: 'miss', judgement: false}])
    }

    const successHits = events.filter(event => event.hit === 'success');
    const missHits = events.filter(event => event.hit === 'miss');

    return <div style={{
        backgroundColor: shouldDance ? '#006501' : '#9d0000',
        textAlign: 'center',
        fontSize: 80,
        flexDirection: 'column',
        width: '1600px',
        height: '900px',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        animation: shouldDance ? 'greenBackground 0.5s infinite alternate' : 'none',
    }}>
        <style>
            {`
               @keyframes greenBackground {
                 0% { background-color: ${'#006501'}; }
                 100% { background-color: ${'#008303'}; }
               }
            `}
        </style>
        <div className={'dancestop'} style={{margin: 2}}>
            {shouldDance ? 'DANCE!' : 'STOP!'}
            {/*<button onClick={addEvent}>Click Me</button>*/}
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%'}}>
            {shouldDance && successHits.length > 0 && <div className={'dancestop'} style={{
                width: '100%',
                fontSize: 180,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: isClearing ? 'opacity 0.5s ease-out' : '',
                opacity: isClearing ? 0 : 1,
            }}>
                {successHits.length} got it!
            </div>}
            {!shouldDance && missHits.map((event, index) =>
                <div key={index} style={{
                    width: '216px',
                    height: '350px',
                    margin: '8px',
                    padding: '8px',
                    backgroundColor: 'black',
                    border: '1px solid white',
                    transition: isClearing ? 'opacity 0.5s ease-out' : '',
                    opacity: isClearing ? 0 : 1,
                }}>
                    <div style={{fontSize: 24, color: 'red', fontWeight: 'bold', paddingBottom: 4}}>MISS</div>
                    <img style={{width: '200px', display: 'block', margin: 'auto'}} src={event.character.image_url}/>
                    <div style={{fontSize: 36}}>{event.character.name}</div>
                    <div style={{fontSize: 28, fontWeight: 'bold'}}>{event.character.nickname}</div>
                </div>)
            }
        </div>
    </div>
}

const MotionPermissionWrapper = ({shouldDance, since, clockDrift}) => {
    const [permissionState, setPermissionState] = useState('unknown');

    const requestPermission = async () => {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
            try {
                const permission = await DeviceMotionEvent.requestPermission();
                setPermissionState(permission);
            } catch (error) {
                console.error('Error requesting device motion permission:', error);
                // setPermissionState('denied');
            }
        } else {
            setPermissionState('granted');
        }
    };

    useEffect(() => {
        if (typeof DeviceMotionEvent.requestPermission !== 'function') {
            setPermissionState('granted');
        }
        requestPermission().then(() => {})
    }, []);

    if (permissionState === 'unknown') {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%'}}>
                <div style={{fontSize: 30}}>Your phone screen must stay active in order to score points.</div>
                <button
                    onClick={requestPermission}
                    style={{
                        padding: '40px',
                        fontSize: '40px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Activate
                </button>
            </div>
        );
    }

    if (permissionState === 'denied') {
        return (
            <div style={{textAlign: 'center', padding: '20px'}}>
                <p>Permission to access device motion was denied.</p>
                <p>Please enable motion sensors in your device settings to use this app.</p>
            </div>
        );
    }

    if (permissionState === 'granted') {
        return <DanceStopPlayer clockDrift={clockDrift} shouldDance={shouldDance} since={since}/>;
    }

    return null;
};


const BUFFER_SIZE = 5000;
const DANCE_THRESHOLD = 2;
const EDGE_GRACE_PERIOD = 400;
const EDGE_REQUIRED_CONSISTENT_PERIOD = 700;

const DanceStopPlayer = ({shouldDance, since, clockDrift}) => {
    const [judgement, setJudgement] = useState({});
    const [isDancing, setIsDancing] = useState(false);
    const [isShowingJudgement, setIsShowingJudgement] = useState(false);
    const [combo, setCombo] = useState(0);
    const [score, setScore] = useState('-')

    const lastProps = useRef({ shouldDance, since });
    const motionBuffer = useRef([]);

    useFirstVisitReload();

    useEffect(() => {
        fetch('/dance-stop/get-score', {credentials: 'include'})
            .then(response => response.json())
            .then(setScore)
    }, []);

    useEffect(() => {
        const handleMotion = (event) => {
            const {acceleration} = event;
            if (acceleration) {
                const magnitude = Math.sqrt(
                    acceleration.x ** 2 + acceleration.y ** 2 + acceleration.z ** 2
                );

                const timestamp = Date.now() + clockDrift;
                motionBuffer.current.push({magnitude, timestamp});

                while (motionBuffer.current.length > 0 && timestamp - motionBuffer.current[0].timestamp > BUFFER_SIZE) {
                    motionBuffer.current.shift();
                }

                setIsDancing(motionBuffer.current.filter(m => m.timestamp >= Date.now() + clockDrift - 200).some(m => m.magnitude > DANCE_THRESHOLD))
            }
        };

        if (window.DeviceMotionEvent) {
            window.addEventListener('devicemotion', handleMotion);
            return () => window.removeEventListener('devicemotion', handleMotion);
        }
    }, []);

    useEffect(() => {
        const judgePerformanceEdge = () => {
            if(!motionBuffer.current.filter(m => m.timestamp < since).length) return;

            const now = Date.now() + clockDrift;
            const judgementDelay = EDGE_GRACE_PERIOD + EDGE_REQUIRED_CONSISTENT_PERIOD
            if (now - since < judgementDelay) {
                setTimeout(judgePerformanceEdge, judgementDelay - (now - since));
                return;
            }

            const beforeEvent = motionBuffer.current.filter(
                m => m.timestamp >= since - EDGE_REQUIRED_CONSISTENT_PERIOD && m.timestamp < since - EDGE_GRACE_PERIOD
            );
            const afterEvent = motionBuffer.current.filter(
                m => m.timestamp >= since + EDGE_GRACE_PERIOD && m.timestamp < since + judgementDelay
            );

            const wasDancingBefore = beforeEvent.some(m => m.magnitude > DANCE_THRESHOLD);
            const wasStillBefore = !wasDancingBefore;
            const wasDancingAfter = afterEvent.some(m => m.magnitude > DANCE_THRESHOLD);
            const wasStillAfter = !wasDancingAfter;

            let judgement;

            if (shouldDance !== lastProps.current.shouldDance || lastProps.current.since === null) {
                judgement = shouldDance ? (wasStillBefore && wasDancingAfter) : (wasDancingBefore && wasStillAfter);
            } else {
                judgement = shouldDance ? (wasDancingBefore && wasDancingAfter) : (wasStillBefore && wasStillAfter);
            }

            setJudgement({judgement, since});

            if (judgement) {
                setCombo(prevCombo => {
                    const newCombo = prevCombo + 1;

                    fetch('/dance-stop/success-hit', {
                        method: 'post',
                        credentials: 'include',
                        body: JSON.stringify({combo: newCombo})
                    }).then(response => response.json())
                        .then(setScore);

                    return newCombo;
                });
            } else {
                setCombo(0);

                fetch('/dance-stop/miss-hit', {
                    method: 'post',
                    credentials: 'include',
                }).then(response => response.json())
                    .then(setScore);
            }

            lastProps.current = { since, shouldDance };
        };

        judgePerformanceEdge();
    }, [shouldDance, since]);

    useEffect(() => {
        if(!motionBuffer.current.filter(m => m.timestamp < since).length) return;
        setIsShowingJudgement(true);
        setTimeout(() => {
            setIsShowingJudgement(false);
        }, 1000)
    }, [judgement])

    const colors = {
        background: '#000000',
        primary: '#b2ff54',
        secondary: '#0f3460',
        accent: '#00f7ff',
        text: '#ffffff',
        success: '#00bb03',
        error: '#ff1e0d'
    };

    const judgementBackgroundColor = judgement.judgement ? colors.success : colors.error;

    return (
        <div style={{
            flex: 1,
            display: 'flex',
            height: '100%',
            width: '100%',
            color: colors.text,
            transition: 'background-color 0.5s ease',
            backgroundColor: 'black'
        }}>
            <style>
                {`
                  @keyframes dancingBackground {
                    0% { color: ${colors.accent}; }
                    100% { color: ${colors.primary}; }
                  }
                `}
            </style>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                height: '100%',
                width: '100%',
                backgroundColor: isShowingJudgement ? judgementBackgroundColor : 'transparent',
                transition: !isShowingJudgement ? 'all 500ms' : '',
                textShadow: `2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black`,
            }}>
                <div style={{
                    fontSize: 90,
                    fontWeight: 'bolder',
                    color: shouldDance ? colors.success : colors.error,
                }}>
                    {shouldDance ? 'DANCE!' : 'STOP!'}
                </div>
                <div>
                    <div className={'digital'} style={{
                        fontSize: 16,
                        background: 'linear-gradient(135deg, #FFDB5EFF, #CEA000FF)',
                        padding: 6,
                        textShadow: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        borderRadius: '12px',
                        border: '1px solid black',
                        display: 'inline-block',
                        marginBottom: 12,
                        transition: combo > 2 ? 'all 500ms' : '',
                        opacity: combo > 2 ? 1 : 0,
                    }}>
                        {combo} COMBO
                    </div>
                    <div className={'digital'} style={{
                        fontSize: 24,
                        color: '#ffc90c',
                        textShadow: `1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black`,
                    }}>
                        <ScoreCounter score={score}/> POINTS
                    </div>
                </div>
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{fontSize: 40, color: colors.text}}>You are</div>

                    </div>
                    <div style={{
                        fontSize: 70,
                        marginBottom: 15,
                        color: colors.text,
                        animation: isDancing ? 'dancingBackground 1.5s infinite alternate' : 'none',
                    }}>
                        {isDancing ? <Wave text="Dancing"/> : 'Stopped'}
                    </div>
                    <div style={{fontSize: 40}}>{isDancing === shouldDance ? '✅' : '❌'}</div>
                </div>
                <div style={{
                    fontSize: 120,
                    fontWeight: 'bold',
                    color: 'black',
                    opacity: 1,
                    textShadow: `none`,
                }}>
                    {judgement.judgement ? 'NICE!' : 'MISS!'}
                </div>
            </div>
        </div>

    );
};
