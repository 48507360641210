import React, {useEffect, useState} from "react";
import {AiSpeech, ReceivingTransmission} from "../ai/ai";
import {useOrbPosition} from "../../common/osc";
import {HighScores} from "../../common/scores";

export const Leaderboard = ({isControl, round}) => {
    if(!isControl) return <ReceivingTransmission />
    else return <LeaderboardControl round={round} />
}

export const LeaderboardControl = ({round}) => {
    const [response, setResponse] = useState({});
    const [index, setIndex] = useState(0);
    const [showAllResults, setShowAllResults] = useState(false);

    useOrbPosition(0.42, 0.18)

    useEffect(() => {
        fetch(`/leaderboard/get-speech?round=${round}`)
            .then(response => response.json())
            .then((json) => {
                const sentenceSplitter = new Intl.Segmenter('en', {
                    granularity: 'sentence'
                });

                json['runnersUpSpeech'] = Array.from(sentenceSplitter.segment(json['runnersUpSpeech']), s => s.segment)
                json['biggestUpsetsSpeech'] = Array.from(sentenceSplitter.segment(json['biggestUpsetsSpeech']), s => s.segment)
                json['winnersSpeech'] = Array.from(sentenceSplitter.segment(json['winnersSpeech']), s => s.segment)

                setResponse(json)
            });
    }, []);

    let currentText;
    let scores;

    if (round < 2) {
        if (!response.runnersUpSpeech) {
            console.log("No speech found")
        } else if (index < response.runnersUpSpeech.length) {
            console.log("Running up speech")
            currentText = response.runnersUpSpeech[index];
            scores = response.runnersUpScores;
        } else {
            console.log("Biggest upset speech")
            currentText = response.winnersSpeech[index - response.runnersUpSpeech.length];
            scores = response.winnersScores;
        }
    } else {
        if (!response.runnersUpSpeech) {
            console.log("No speech found")
        } else if (index < response.runnersUpSpeech.length) {
            console.log("Running up speech")
            currentText = response.runnersUpSpeech[index];
            scores = response.runnersUpScores;
        } else if (index < response.runnersUpSpeech.length + response.biggestUpsetsSpeech.length) {
            console.log("Biggest upset speech")
            currentText = response.biggestUpsetsSpeech[index - response.runnersUpSpeech.length];
            scores = response.biggestUpsetsScores;
        } else {
            console.log("Winners speech")
            currentText = response.winnersSpeech[index - response.runnersUpSpeech.length - response.biggestUpsetsSpeech.length];
            scores = response.winnersScores;
        }
    }

    const completedCallback = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            console.log(newIndex)
            console.log(response.runnersUpSpeech.length + response.biggestUpsetsSpeech.length + response.winnersSpeech.length)

            if(response.runnersUpSpeech && round === 1 && newIndex >= response.runnersUpSpeech.length + response.winnersSpeech.length) setShowAllResults(true);
            if(response.runnersUpSpeech && round > 1 && newIndex >= response.runnersUpSpeech.length + response.biggestUpsetsSpeech.length + response.winnersSpeech.length) setShowAllResults(true);
            return newIndex;
        });
    }

    const indicator = (score) => {
        if(!score.trend || !score.rank_change) {
            return "";
        } else if(score.trend === "new") {
            return "🆕";
        } else if(score.rank_change > 0) {
            return <div style={{color: 'lightGreen'}}>{score.rank_change + "⏶"}</div>
        } else if(score.rank_change < 0) {
            return <div style={{color: '#ff5757'}}>{score.rank_change + "⏷"}</div>
        }
    }

    if(showAllResults) return <HighScores scoresEndpoint={'/leaderboard/get-leaderboard?round=' + round} title={'Totals'} isControl={true} />;

    if (!currentText) return;
    if (!scores) return;

    return <div style={{textAlign: 'center', fontSize: 80, width: '1500px', height: '900px', alignSelf: 'flex-start', display: 'flex', alignItems: 'flex-end'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column'}}>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    width: '1500px',
                }}
            ><AiSpeech text={currentText} isControl={true} completedCallback={completedCallback}/></div>
            <div style={{flex: 1}}>
                <div></div>
            </div>
            <div style={{flex: 1, display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                {scores.map((score) =>
                    <div key={score.character.token} className={`highscores-${score.rank <= 5 ? score.rank : 5}`}
                         style={{
                             margin: 6,
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                             height: '146px',
                             flex: 1,
                         }}>
                        <div style={{textAlign: 'right', width: 60}}>
                            <div style={{fontSize: 56}}>{score.rank}.</div>
                        </div>
                        <div style={{alignSelf: 'flex-start', fontSize: 50, marginRight: 12}}>
                            {indicator(score)}
                        </div>
                        <img style={{boxShadow: '0 0 3px #000, 0 0 5px #000'}} src={score.character.image_url}
                             height={132}
                             width={132}/>
                        <div style={{paddingLeft: 10, flex: 1, textAlign: 'left'}}>
                            <div style={{fontSize: 40}}>{score.character.name}</div>
                            <div style={{fontSize: 40, fontWeight: 'bold'}}>{score.character.nickname}</div>
                        </div>
                        <div style={{fontSize: 86, margin: 18, textAlign: 'right'}}>
                            {score.score}
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>

    return <>
        <div style={{fontSize: 70, textAlign: 'center', width: '1500px', alignSelf: 'flex-start'}}>

        </div>
    </>;
}