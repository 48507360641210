import React, {useEffect, useState} from "react";
import {create} from "simple-drawing-board";
import {TextScene} from "./text";

export const Ending = ({name, isControl}) => {
    const [savedCharacter, setSavedCharacter] = useState();
    const [dancelestrationsSummary, setDancelestrationsSummary] = useState();
    const [chaosCharactersScore, setChaosCharactersScore] = useState(0);

    useEffect(() => {
        if (isControl) return;

        fetch('/character-creator/get-character', {credentials: 'include'})
            .then(response => response.json())
            .then((json) => {
                setSavedCharacter(json);
            });

        fetch('/dancelestrations/get-personal-summary', {credentials: 'include'})
            .then(response => response.json())
            .then(setDancelestrationsSummary);

        fetch('/chaos-characters/get-score', {credentials: 'include'})
            .then(response => response.json())
            .then((json) => setChaosCharactersScore(json.score))
    }, []);

    useEffect(() => {
        if (!savedCharacter || !dancelestrationsSummary) return;

        const initialSdb = create(document.getElementById("initial-drawing"));
        const danceSdb = create(document.getElementById("dance-drawing"));

        if (dancelestrationsSummary.initial_drawing) {
            initialSdb.fillImageByDataURL(dancelestrationsSummary.initial_drawing);
        } else {
            initialSdb.clear();
        }

        if (dancelestrationsSummary.dance_guess_drawing) {
            danceSdb.fillImageByDataURL(dancelestrationsSummary.dance_guess_drawing);
        } else {
            danceSdb.clear();
        }

        return () => {
            initialSdb.destroy()
            danceSdb.destroy();
        }
    }, [dancelestrationsSummary]);

    if (isControl) return;
    if (!savedCharacter || !dancelestrationsSummary) return;

    return <div style={{position: 'absolute', top: 20, textAlign: 'center'}}>
        <div className={'digital'} style={{paddingBottom: 10}}>Thanks For Playing!</div>
        <div style={{fontSize: 50}}>{name}</div>
        <div style={{fontSize: 30, fontWeight: 'bold', paddingBottom: 20}}>{savedCharacter.nickname}</div>
        <img src={savedCharacter.image_url} height='300' width='300'/>
        <div style={{
            fontSize: 24,
            margin: 30,
            padding: 10,
            border: '2px dotted white'
        }}>{savedCharacter.get_down_story}</div>
        <div style={{fontSize: 36, fontWeight: 'bold'}}>✨<br/><span style={{textDecoration: 'underline'}}>Dancing Animation</span>
        </div>
        {dancelestrationsSummary.dancing_image_url ?
            <img style={{width: '300px', objectFit: 'contain', paddingTop: 20}}
                 src={dancelestrationsSummary.dancing_image_url}/>
            :
            <div style={{fontSize: 28, paddingTop: 10}}>No Animation :(</div>
        }
        <div style={{fontSize: 36, fontWeight: 'bold', paddingTop: 40}}>🖌️<br/><span
            style={{textDecoration: 'underline'}}>Drawings</span></div>
        <div style={{fontSize: 28, fontWeight: 'bold', padding: 10}}>{dancelestrationsSummary.initial_word}</div>
        <canvas className="drawing-story" id="initial-drawing" width={'500px'} height={'500px'}
                style={{width: '300px', height: '300px', border: '1px dotted white'}}/>
        <div style={{
            fontSize: 28,
            fontWeight: 'bold',
            padding: 10
        }}>{dancelestrationsSummary.dance_guess_word ? dancelestrationsSummary.dance_guess_word : 'Anything'}</div>
        <canvas className="drawing-story" id="dance-drawing" width={'500px'} height={'500px'}
                style={{width: '300px', height: '300px', border: '1px dotted white'}}/>
        <div style={{fontSize: 36, fontWeight: 'bold', paddingTop: 40}}>🦹🏽<br/><span
            style={{textDecoration: 'underline'}}>Chaos Characters</span></div>
        <div style={{fontSize: 28, padding: 20, paddingBottom: 50}}>Score: {chaosCharactersScore}/5</div>
    </div>;
}